/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AfiListAssetInfo,
  Asset,
  AssetPaginationResult,
  CheckIn,
  ClassificationQuestion,
  ClassificationSet,
  Comment,
  CommentCreation,
  CreateAsset,
  DeviceClassificationCategory,
  EOLBatchItem,
  EmailConfirmationRequest,
  EndCustomer,
  EndCustomerCreation,
  EndCustomerGroup,
  Error,
  ForgotPasswordRequest,
  Inspection,
  LoginRequest,
  MissingAssets,
  NewPartner,
  NewProductCode,
  OrderRemoval,
  OrderUpsertion,
  OrderV2,
  OrderV2PaginationResult,
  Partner,
  Pause,
  PauseEditLogItem,
  PauseUpsertion,
  PriceListsDto,
  ProblemDetails,
  ProductCode,
  ProductCodeUpsert,
  ResetPasswordRequest,
  Settings,
  ShippingStatus,
  StateChangeV2,
  Storage,
  Tag,
  User,
  UserAccess,
  UserAccessCreation,
  UserLanguage,
  UserNames,
  VerificationRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags AssetReport
   * @name AssetsReportList
   * @request GET:/api/assets/report
   */
  assetsReportList = (
    query?: {
      states?: number[];
      /** @format int32 */
      orderId?: number;
      /** @format int32 */
      batchId?: number;
      /** @format int32 */
      partnerId?: number;
      /** @format int32 */
      endCustomerId?: number;
      storageName?: string;
      referenceNumber?: string;
      serialNumber?: string;
      trackingNumber?: string;
      /** @format date-time */
      checkedInFrom?: string;
      /** @format date-time */
      checkedInTo?: string;
      /** @format date-time */
      classificationFrom?: string;
      /** @format date-time */
      classificationTo?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<File, Error>({
      path: `/api/assets/report`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsDetail
   * @request GET:/api/assets/{id}
   */
  assetsDetail = (id: number, params: RequestParams = {}) =>
    this.request<EOLBatchItem, any>({
      path: `/api/assets/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsV2Detail
   * @request GET:/api/assets/v2/{id}
   */
  assetsV2Detail = (id: number, params: RequestParams = {}) =>
    this.request<Asset, ProblemDetails>({
      path: `/api/assets/v2/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsList
   * @request GET:/api/assets
   */
  assetsList = (
    query?: {
      /**
       * @format int32
       * @min 1
       * @max 2147483647
       */
      PageNumber?: number;
      /**
       * @format int32
       * @min 10
       * @max 10000
       */
      PageSize?: number;
      /** @format int32 */
      batchId?: number;
      /** @format int32 */
      orderId?: number;
      /** @format int32 */
      endCustomerId?: number;
      /** @format int32 */
      partnerId?: number;
      search?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<AssetPaginationResult, any>({
      path: `/api/assets`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsUpdate
   * @request PUT:/api/assets
   */
  assetsUpdate = (data: EOLBatchItem[], params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/assets`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsBySerialNumberV2List
   * @request GET:/api/assets/by-serial-number/v2
   */
  assetsBySerialNumberV2List = (
    query: {
      serialNumber: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Asset[], ProblemDetails>({
      path: `/api/assets/by-serial-number/v2`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsByTrackingNumberV2Detail
   * @request GET:/api/assets/by-tracking-number/v2/{trackingNumber}
   */
  assetsByTrackingNumberV2Detail = (
    trackingNumber: string,
    params: RequestParams = {},
  ) =>
    this.request<Asset, ProblemDetails>({
      path: `/api/assets/by-tracking-number/v2/${trackingNumber}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsByTrackingNumberUpdate
   * @request PUT:/api/assets/by-tracking-number/{trackingNumber}
   */
  assetsByTrackingNumberUpdate = (
    trackingNumber: string,
    data: CreateAsset,
    params: RequestParams = {},
  ) =>
    this.request<Asset, ProblemDetails>({
      path: `/api/assets/by-tracking-number/${trackingNumber}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsByTrackingNumbersCreate
   * @request POST:/api/Assets/ByTrackingNumbers
   */
  assetsByTrackingNumbersCreate = (
    data: string[][],
    params: RequestParams = {},
  ) =>
    this.request<AfiListAssetInfo[], any>({
      path: `/api/Assets/ByTrackingNumbers`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsMassUpdateBatchSelectedAssetsInfosCreate
   * @request POST:/api/assets/mass-update-batch-selected-assets/infos
   */
  assetsMassUpdateBatchSelectedAssetsInfosCreate = (
    data: string[][],
    params: RequestParams = {},
  ) =>
    this.request<Record<string, number>, any>({
      path: `/api/assets/mass-update-batch-selected-assets/infos`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name AssetsMassUpdateBatchSelectedAssetsStatesCreate
   * @request POST:/api/assets/mass-update-batch-selected-assets/states
   */
  assetsMassUpdateBatchSelectedAssetsStatesCreate = (
    data: string[][],
    query?: {
      /** @format int32 */
      newState?:
        | 0
        | 10
        | 100
        | 110
        | 120
        | 200
        | 210
        | 220
        | 290
        | 300
        | 400
        | 405
        | 410
        | 420
        | 430
        | 450
        | 451
        | 452
        | 453
        | 454
        | 455
        | 456
        | 457
        | 458
        | 459
        | 460
        | 461;
    },
    params: RequestParams = {},
  ) =>
    this.request<Record<string, number>, any>({
      path: `/api/assets/mass-update-batch-selected-assets/states`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthLoginCreate
   * @request POST:/api/auth/login
   */
  authLoginCreate = (data: LoginRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/auth/login`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthPostSsoLoginCreate
   * @request POST:/api/auth/post-sso-login
   */
  authPostSsoLoginCreate = (params: RequestParams = {}) =>
    this.request<User, ProblemDetails>({
      path: `/api/auth/post-sso-login`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthVerifyCreate
   * @request POST:/api/auth/verify
   */
  authVerifyCreate = (data: VerificationRequest, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/auth/verify`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthForgotPasswordCreate
   * @request POST:/api/auth/forgotPassword
   */
  authForgotPasswordCreate = (
    data: ForgotPasswordRequest,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/auth/forgotPassword`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthResetPasswordCreate
   * @request POST:/api/auth/resetPassword
   */
  authResetPasswordCreate = (
    data: ResetPasswordRequest,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/auth/resetPassword`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthConfirmEmailCreate
   * @request POST:/api/auth/confirmEmail
   */
  authConfirmEmailCreate = (
    data: EmailConfirmationRequest,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/auth/confirmEmail`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Batches
   * @name BatchesOrderIdDetail
   * @request GET:/api/batches/{batchId}/order-id
   */
  batchesOrderIdDetail = (batchId: number, params: RequestParams = {}) =>
    this.request<number, ProblemDetails>({
      path: `/api/batches/${batchId}/order-id`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Blancco
   * @name BlanccoCallbackDetail
   * @request GET:/api/blancco/callback/{extra}/{uuid}
   */
  blanccoCallbackDetail = (
    uuid: string,
    extra: string,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/blancco/callback/${extra}/${uuid}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Blancco
   * @name BlanccoStatusUpdateCreate
   * @request POST:/api/blancco/statusUpdate/{batchId}
   */
  blanccoStatusUpdateCreate = (batchId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/blancco/statusUpdate/${batchId}`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags CheckIn
   * @name AssetsCheckInCreate
   * @request POST:/api/assets/{assetId}/check-in
   */
  assetsCheckInCreate = (
    assetId: number,
    data: CheckIn,
    params: RequestParams = {},
  ) =>
    this.request<Asset, Error | ProblemDetails>({
      path: `/api/assets/${assetId}/check-in`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CheckIn
   * @name AssetsCheckInCreate2
   * @request POST:/api/assets/check-in
   * @originalName assetsCheckInCreate
   * @duplicate
   */
  assetsCheckInCreate2 = (data: CreateAsset, params: RequestParams = {}) =>
    this.request<Asset, Error>({
      path: `/api/assets/check-in`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClassificationCategories
   * @name OrdersClassificationCategoriesDetail
   * @request GET:/api/orders/{orderId}/classification-categories
   */
  ordersClassificationCategoriesDetail = (
    orderId: number,
    params: RequestParams = {},
  ) =>
    this.request<DeviceClassificationCategory[], ProblemDetails>({
      path: `/api/orders/${orderId}/classification-categories`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClassificationCategories
   * @name PartnersClassificationCategoriesDetail
   * @request GET:/api/partners/{id}/classification-categories
   */
  partnersClassificationCategoriesDetail = (
    id: number,
    params: RequestParams = {},
  ) =>
    this.request<DeviceClassificationCategory[], ProblemDetails>({
      path: `/api/partners/${id}/classification-categories`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClassificationQuestions
   * @name AssetsClassificationQuestionsDetail
   * @request GET:/api/assets/{assetId}/classification-questions
   */
  assetsClassificationQuestionsDetail = (
    assetId: number,
    params: RequestParams = {},
  ) =>
    this.request<ClassificationQuestion[], ProblemDetails>({
      path: `/api/assets/${assetId}/classification-questions`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClassificationSet
   * @name ClassificationSetsCreate
   * @request POST:/api/classification-sets
   */
  classificationSetsCreate = (
    data: ClassificationSet,
    params: RequestParams = {},
  ) =>
    this.request<void, Error>({
      path: `/api/classification-sets`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Cleanup
   * @name AssetsCleanupCreate
   * @request POST:/api/assets/{trackingNumber}/cleanup
   */
  assetsCleanupCreate = (trackingNumber: string, params: RequestParams = {}) =>
    this.request<Asset, Error | ProblemDetails>({
      path: `/api/assets/${trackingNumber}/cleanup`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClientDepot
   * @name ProductCodesCreate
   * @request POST:/api/product-codes
   */
  productCodesCreate = (data: NewProductCode, params: RequestParams = {}) =>
    this.request<ProductCode, Error | ProblemDetails>({
      path: `/api/product-codes`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClientDepot
   * @name ProductCodesList
   * @request GET:/api/product-codes
   */
  productCodesList = (
    query?: {
      /** @format int32 */
      endCustomerId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProductCode[], any>({
      path: `/api/product-codes`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ClientDepot
   * @name AssetsProductCodesCreate
   * @request POST:/api/assets/product-codes
   */
  assetsProductCodesCreate = (
    data: ProductCodeUpsert[],
    params: RequestParams = {},
  ) =>
    this.request<void, Error | ProblemDetails>({
      path: `/api/assets/product-codes`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Comments
   * @name CommentsCreate
   * @request POST:/api/comments
   */
  commentsCreate = (data: CommentCreation, params: RequestParams = {}) =>
    this.request<Comment[], Error | ProblemDetails>({
      path: `/api/comments`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Comments
   * @name CommentsList
   * @request GET:/api/comments
   */
  commentsList = (
    query: {
      /** @format int32 */
      orderId: number;
      isInternal: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Comment[], Error | ProblemDetails>({
      path: `/api/comments`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomerGroup
   * @name EndCustomerGroupsList
   * @request GET:/api/end-customer-groups
   */
  endCustomerGroupsList = (params: RequestParams = {}) =>
    this.request<EndCustomerGroup[], any>({
      path: `/api/end-customer-groups`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomerGroup
   * @name EndCustomersGroupsDetail
   * @request GET:/api/end-customers/{endCustomerId}/groups
   */
  endCustomersGroupsDetail = (
    endCustomerId: number,
    params: RequestParams = {},
  ) =>
    this.request<EndCustomerGroup[], void>({
      path: `/api/end-customers/${endCustomerId}/groups`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomerGroup
   * @name EndCustomersGroupsUpdate
   * @request PUT:/api/end-customers/{endCustomerId}/groups/{groupId}
   */
  endCustomersGroupsUpdate = (
    endCustomerId: number,
    groupId: string,
    params: RequestParams = {},
  ) =>
    this.request<EndCustomerGroup, void>({
      path: `/api/end-customers/${endCustomerId}/groups/${groupId}`,
      method: "PUT",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomerGroup
   * @name EndCustomersGroupsDelete
   * @request DELETE:/api/end-customers/{endCustomerId}/groups/{groupId}
   */
  endCustomersGroupsDelete = (
    endCustomerId: number,
    groupId: string,
    params: RequestParams = {},
  ) =>
    this.request<void, void>({
      path: `/api/end-customers/${endCustomerId}/groups/${groupId}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomers
   * @name EndCustomersCreate
   * @request POST:/api/end-customers
   */
  endCustomersCreate = (
    data: EndCustomerCreation,
    params: RequestParams = {},
  ) =>
    this.request<EndCustomer, Error>({
      path: `/api/end-customers`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomers
   * @name EndCustomersList
   * @request GET:/api/end-customers
   */
  endCustomersList = (
    query?: {
      /** @default false */
      showDisabled?: boolean;
      /** @format int32 */
      partnerId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<EndCustomer[], ProblemDetails>({
      path: `/api/end-customers`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomers
   * @name EndCustomersUpdate
   * @request PUT:/api/end-customers
   */
  endCustomersUpdate = (data: EndCustomer, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/end-customers`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomers
   * @name EndCustomersDetail
   * @request GET:/api/end-customers/{id}
   */
  endCustomersDetail = (id: number, params: RequestParams = {}) =>
    this.request<EndCustomer, ProblemDetails>({
      path: `/api/end-customers/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomers
   * @name EndCustomersDelete
   * @request DELETE:/api/end-customers/{id}
   */
  endCustomersDelete = (id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/end-customers/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags EndCustomers
   * @name EndCustomersTagCreate
   * @request POST:/api/end-customers/{id}/tag
   */
  endCustomersTagCreate = (id: number, data: Tag, params: RequestParams = {}) =>
    this.request<Tag, ProblemDetails>({
      path: `/api/end-customers/${id}/tag`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Erasure
   * @name ErasureAssetPdfDetail
   * @request GET:/api/erasure/asset/pdf/{assetId}
   */
  erasureAssetPdfDetail = (assetId: number, params: RequestParams = {}) =>
    this.request<File, ProblemDetails>({
      path: `/api/erasure/asset/pdf/${assetId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Erasure
   * @name ErasureBatchPdfDetail
   * @request GET:/api/erasure/batch/pdf/{batchId}
   */
  erasureBatchPdfDetail = (batchId: number, params: RequestParams = {}) =>
    this.request<File, ProblemDetails>({
      path: `/api/erasure/batch/pdf/${batchId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Inspection
   * @name AssetsInspectionDetail
   * @request GET:/api/assets/{trackingNumber}/inspection
   */
  assetsInspectionDetail = (
    trackingNumber: string,
    params: RequestParams = {},
  ) =>
    this.request<Inspection, ProblemDetails>({
      path: `/api/assets/${trackingNumber}/inspection`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Inspection
   * @name AssetsInspectionUpdate
   * @request PUT:/api/assets/{trackingNumber}/inspection
   */
  assetsInspectionUpdate = (
    trackingNumber: string,
    data: Inspection,
    params: RequestParams = {},
  ) =>
    this.request<Inspection, Error | ProblemDetails>({
      path: `/api/assets/${trackingNumber}/inspection`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InterimStorage
   * @name AssetsStorageCreate
   * @request POST:/api/assets/storage
   */
  assetsStorageCreate = (data: Storage, params: RequestParams = {}) =>
    this.request<Storage, Error>({
      path: `/api/assets/storage`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MissingAssets
   * @name OrdersMissingAssetsDetail
   * @request GET:/api/orders/{id}/missing-assets
   */
  ordersMissingAssetsDetail = (id: number, params: RequestParams = {}) =>
    this.request<MissingAssets, ProblemDetails>({
      path: `/api/orders/${id}/missing-assets`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MissingAssets
   * @name OrdersMissingAssetsCreate
   * @request POST:/api/orders/{id}/missing-assets
   */
  ordersMissingAssetsCreate = (id: number, params: RequestParams = {}) =>
    this.request<void, Error | ProblemDetails>({
      path: `/api/orders/${id}/missing-assets`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersCreate
   * @request POST:/api/orders
   */
  ordersCreate = (data: OrderUpsertion, params: RequestParams = {}) =>
    this.request<OrderV2, Error | ProblemDetails>({
      path: `/api/orders`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersList
   * @request GET:/api/orders
   */
  ordersList = (
    query?: {
      /**
       * @format int32
       * @min 1
       * @max 2147483647
       */
      PageNumber?: number;
      /**
       * @format int32
       * @min 10
       * @max 10000
       */
      PageSize?: number;
      /** @default false */
      showRemoved?: boolean;
      /** @format int32 */
      partnerId?: number;
      /** @format int32 */
      endCustomerId?: number;
      /** @format date-time */
      orderCreatedOrModifiedBegin?: string;
      /** @format date-time */
      orderCreatedOrModifiedEnd?: string;
      search?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<OrderV2PaginationResult, Error | ProblemDetails>({
      path: `/api/orders`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersV2Update
   * @request PUT:/api/orders/v2/{id}
   */
  ordersV2Update = (
    id: number,
    data: OrderUpsertion,
    params: RequestParams = {},
  ) =>
    this.request<OrderV2, Error | ProblemDetails>({
      path: `/api/orders/v2/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersDetail
   * @request GET:/api/orders/{id}
   */
  ordersDetail = (id: number, params: RequestParams = {}) =>
    this.request<OrderV2, Error | ProblemDetails>({
      path: `/api/orders/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersDelete
   * @request DELETE:/api/orders/{id}
   */
  ordersDelete = (id: number, data: OrderRemoval, params: RequestParams = {}) =>
    this.request<void, ProblemDetails>({
      path: `/api/orders/${id}`,
      method: "DELETE",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersConfirmCreate
   * @request POST:/api/orders/{id}/confirm
   */
  ordersConfirmCreate = (id: number, params: RequestParams = {}) =>
    this.request<void, ProblemDetails>({
      path: `/api/orders/${id}/confirm`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersDeclineCreate
   * @request POST:/api/orders/{id}/decline
   */
  ordersDeclineCreate = (id: number, params: RequestParams = {}) =>
    this.request<void, ProblemDetails>({
      path: `/api/orders/${id}/decline`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersSendToMpgCreate
   * @request POST:/api/Orders/{orderId}/sendToMpg
   */
  ordersSendToMpgCreate = (orderId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Orders/${orderId}/sendToMpg`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags Orders
   * @name OrdersFetchmpgordersList
   * @request GET:/api/Orders/fetchmpgorders
   */
  ordersFetchmpgordersList = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Orders/fetchmpgorders`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Partners
   * @name PartnersList
   * @request GET:/api/partners
   */
  partnersList = (params: RequestParams = {}) =>
    this.request<Record<string, Partner>, any>({
      path: `/api/partners`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Partners
   * @name PartnersCreate
   * @request POST:/api/partners
   */
  partnersCreate = (data: NewPartner, params: RequestParams = {}) =>
    this.request<Partner, any>({
      path: `/api/partners`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Partners
   * @name PartnersUpdate
   * @request PUT:/api/partners
   */
  partnersUpdate = (data: Partner, params: RequestParams = {}) =>
    this.request<Partner, Error | ProblemDetails>({
      path: `/api/partners`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Partners
   * @name PartnersDetail
   * @request GET:/api/partners/{id}
   */
  partnersDetail = (id: number, params: RequestParams = {}) =>
    this.request<Partner, ProblemDetails>({
      path: `/api/partners/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Pause
   * @name OrdersPausesCreate
   * @request POST:/api/orders/{orderId}/pauses
   */
  ordersPausesCreate = (
    orderId: number,
    data: PauseUpsertion,
    params: RequestParams = {},
  ) =>
    this.request<Pause, Error | ProblemDetails>({
      path: `/api/orders/${orderId}/pauses`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Pause
   * @name PausesDetail
   * @request GET:/api/pauses/{id}
   */
  pausesDetail = (
    id: string,
    query?: {
      /** @default false */
      includeRemoved?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Pause, ProblemDetails>({
      path: `/api/pauses/${id}`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Pause
   * @name PausesUpdate
   * @request PUT:/api/pauses/{id}
   */
  pausesUpdate = (
    id: string,
    data: PauseUpsertion,
    params: RequestParams = {},
  ) =>
    this.request<Pause, Error | ProblemDetails>({
      path: `/api/pauses/${id}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Pause
   * @name PausesDelete
   * @request DELETE:/api/pauses/{id}
   */
  pausesDelete = (id: string, params: RequestParams = {}) =>
    this.request<void, ProblemDetails>({
      path: `/api/pauses/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags Pause
   * @name PausesList
   * @request GET:/api/pauses
   */
  pausesList = (
    query?: {
      /** @format int32 */
      orderId?: number;
      /** @default false */
      includeRemoved?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Pause[], ProblemDetails>({
      path: `/api/pauses`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Pause
   * @name PausesEditLogsDetail
   * @request GET:/api/pauses/{id}/edit-logs
   */
  pausesEditLogsDetail = (id: string, params: RequestParams = {}) =>
    this.request<PauseEditLogItem[], ProblemDetails>({
      path: `/api/pauses/${id}/edit-logs`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Price
   * @name PricesCreate
   * @request POST:/api/prices/{partnerId}
   */
  pricesCreate = (
    partnerId: number,
    data: PriceListsDto,
    params: RequestParams = {},
  ) =>
    this.request<void, ProblemDetails>({
      path: `/api/prices/${partnerId}`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Price
   * @name PricesReportDetail
   * @request GET:/api/prices/{partnerId}/report
   */
  pricesReportDetail = (
    partnerId: number,
    query?: {
      endCustomerId?: number[];
      batchId?: string[];
      /** @format date-time */
      pricingPeriodFrom?: string;
      /** @format date-time */
      pricingPeriodTo?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/prices/${partnerId}/report`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Price
   * @name PricesOrderServicePricesDetail
   * @request GET:/api/prices/{partnerId}/order-service-prices
   */
  pricesOrderServicePricesDetail = (
    partnerId: number,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/prices/${partnerId}/order-service-prices`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Price
   * @name PricesCategoryPricesDetail
   * @request GET:/api/prices/{partnerId}/category-prices
   */
  pricesCategoryPricesDetail = (
    partnerId: number,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/prices/${partnerId}/category-prices`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Price
   * @name PricesEndStatePricesDetail
   * @request GET:/api/prices/{partnerId}/end-state-prices
   */
  pricesEndStatePricesDetail = (
    partnerId: number,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/prices/${partnerId}/end-state-prices`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Settings
   * @name PartnersSettingsDetail
   * @request GET:/api/partners/{id}/settings
   */
  partnersSettingsDetail = (id: number, params: RequestParams = {}) =>
    this.request<Settings, any>({
      path: `/api/partners/${id}/settings`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Settings
   * @name PartnersSettingsUpdate
   * @request PUT:/api/partners/{id}/settings
   */
  partnersSettingsUpdate = (
    id: number,
    data: Settings,
    params: RequestParams = {},
  ) =>
    this.request<Settings, any>({
      path: `/api/partners/${id}/settings`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ShipmentTracking
   * @name OrdersShipmentTrackingDetail
   * @request GET:/api/orders/{id}/shipment-tracking
   */
  ordersShipmentTrackingDetail = (id: number, params: RequestParams = {}) =>
    this.request<ShippingStatus, ProblemDetails>({
      path: `/api/orders/${id}/shipment-tracking`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags StateChange
   * @name AssetsStateChangesDetail
   * @request GET:/api/assets/{id}/state-changes
   */
  assetsStateChangesDetail = (id: number, params: RequestParams = {}) =>
    this.request<StateChangeV2[], any>({
      path: `/api/assets/${id}/state-changes`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name EndCustomersUsersCreate
   * @request POST:/api/end-customers/{endCustomerId}/users
   */
  endCustomersUsersCreate = (
    endCustomerId: number,
    data: UserAccessCreation,
    params: RequestParams = {},
  ) =>
    this.request<UserAccess, ProblemDetails | Error>({
      path: `/api/end-customers/${endCustomerId}/users`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name PartnersUsersCreate
   * @request POST:/api/partners/{partnerId}/users
   */
  partnersUsersCreate = (
    partnerId: number,
    data: UserAccessCreation,
    params: RequestParams = {},
  ) =>
    this.request<UserAccess, Error | ProblemDetails>({
      path: `/api/partners/${partnerId}/users`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UsersDetail
   * @request GET:/api/users/{id}
   */
  usersDetail = (id: number, params: RequestParams = {}) =>
    this.request<User, ProblemDetails>({
      path: `/api/users/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UsersDelete
   * @request DELETE:/api/users/{id}
   */
  usersDelete = (id: number, params: RequestParams = {}) =>
    this.request<void, ProblemDetails>({
      path: `/api/users/${id}`,
      method: "DELETE",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name EndCustomersUsersDetail
   * @request GET:/api/end-customers/{id}/users
   */
  endCustomersUsersDetail = (id: number, params: RequestParams = {}) =>
    this.request<UserAccess[], ProblemDetails>({
      path: `/api/end-customers/${id}/users`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name PartnersUsersDetail
   * @request GET:/api/partners/{id}/users
   */
  partnersUsersDetail = (id: number, params: RequestParams = {}) =>
    this.request<UserAccess[], ProblemDetails>({
      path: `/api/partners/${id}/users`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UsersLoggedInUserList
   * @request GET:/api/users/logged-in-user
   */
  usersLoggedInUserList = (params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/api/users/logged-in-user`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UsersLanguageUpdate
   * @request PUT:/api/users/{id}/language
   */
  usersLanguageUpdate = (
    id: number,
    data: UserLanguage,
    params: RequestParams = {},
  ) =>
    this.request<void, ProblemDetails>({
      path: `/api/users/${id}/language`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name UsersNamesUpdate
   * @request PUT:/api/users/{id}/names
   */
  usersNamesUpdate = (
    id: number,
    data: UserNames,
    params: RequestParams = {},
  ) =>
    this.request<UserAccess, ProblemDetails>({
      path: `/api/users/${id}/names`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
