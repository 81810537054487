import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useQueryOrderById } from "@/lib/hooks/query/order/useQueryOrderById";
import { useStateIdTranslation } from "@/lib/hooks/useStateIdTranslation";
import { CopyFormState } from "@/lib/modals/pauses/CopyPauseModal/types";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useDebounce } from "@uidotdev/usehooks";
import { useState, useRef, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

export function OrderSearchField({ sourceOrderId }: { sourceOrderId: number }) {
  const { t } = useTranslation("", { keyPrefix: "common.modal.copy-pause" });
  const stateT = useStateIdTranslation();

  const form = useFormContext<CopyFormState>();

  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("");

  const debouncedFilter = useDebounce(filter, 300);

  const orders = form.watch("orders");

  const orderQuery = useQueryOrderById(parseInt(debouncedFilter), {
    enabled: !isNaN(parseInt(debouncedFilter)),
    retry: 0,
  });

  const searchRef = useRef<HTMLDivElement>(null);

  const maxWidth = searchRef.current?.offsetWidth;

  const handleAddOrder = useCallback(() => {
    if (filter === "") return;
    if (orderQuery.status !== "success" || !orderQuery.data) {
      throw new Error("Order not present");
    }
    if (orderQuery.data.id === sourceOrderId) {
      toast.error("Cannot copy pause to the same order");
      return;
    }
    if (orders.some((o) => o.id === orderQuery.data.id)) {
      toast.error("Order already added");
      return;
    }
    form.setValue("orders", [...orders, orderQuery.data]);
    setFilter("");
    setOpen(false);
  }, [filter, orderQuery.status, orderQuery.data, sourceOrderId, orders, form]);

  return (
    <Popover open={open && debouncedFilter.length > 0} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <div
          ref={searchRef}
          className="relative flex flex-grow items-center gap-2"
        >
          <MagnifyingGlassIcon className="pointer-events-none absolute left-3 size-5" />
          <Input
            placeholder={t("search-for-order-with-order-number")}
            className="pl-10"
            value={filter}
            onChange={(e) => {
              setOpen(true);
              setFilter(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleAddOrder();
              }
            }}
            autoComplete="off"
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        className="flex flex-row justify-between gap-4 hover:bg-slate-100"
        style={{ width: maxWidth }}
        align="start"
        onOpenAutoFocus={(e) => e.preventDefault()}
        onClick={(e) => {
          e.stopPropagation();
          handleAddOrder();
        }}
      >
        {orderQuery.status === "pending" && <div>{t("loading")}</div>}
        {orderQuery.status === "error" && <div>{t("order-not-found")}</div>}
        {orderQuery.status === "success" && (
          <>
            <Field label={t("order-id")} value={orderQuery.data?.id} />
            <Field label={t("batch-id")} value={orderQuery.data?.batchId} />
            <Field
              label={t("created-at")}
              value={new Date(
                orderQuery.data?.orderCreateDate,
              ).toLocaleDateString("fi-Fi")}
            />
            <Field
              label={t("partner-client")}
              value={`${orderQuery.data.partnerName}/${orderQuery.data.endCustomerName}`}
            />
            <Field
              label={t("state")}
              value={stateT(orderQuery.data?.orderStateId)}
            />
          </>
        )}
      </PopoverContent>
    </Popover>
  );
}

function Field({ label, value }: { label: string; value: string | number }) {
  return (
    <div className="flex flex-col gap-2">
      <Label className="font-bold text-[#7C8B9D]">{label}</Label>
      <span className="text-[#7C8B9D]">{value}</span>
    </div>
  );
}
