import { Button } from "@/components/ui/button";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "@/components/ui/dialog";

import { Label } from "@/components/ui/label";
import { useMutateRemovePause } from "@/lib/hooks/query/pause/useMutateRemovePause";
import { useModal } from "@/lib/modals/useModal";
import {
  ParsedPause,
  PauseReasonType,
  PauseSolutionType,
} from "@/lib/types/pause";

import { Loader2 } from "lucide-react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface RemovePauseModalProps {
  pause: ParsedPause;
}

export function RemovePauseModal({ pause }: RemovePauseModalProps) {
  const { t } = useTranslation("", { keyPrefix: "common.modal.remove-pause" });
  const { hideModal } = useModal();

  const { mutateAsync, isPending } = useMutateRemovePause();

  const handleDelete = useCallback(async () => {
    await mutateAsync({ pauseId: pause.id });
    hideModal();
  }, [hideModal, mutateAsync, pause.id]);

  const handleCancel = () => {
    hideModal();
  };

  const dateRange = useMemo(() => {
    const startedAt = pause.startedAt.toLocaleDateString("fi-en");
    const solvedAt =
      pause.status === "finished"
        ? pause.solvedAt.toLocaleDateString("fi-en")
        : null;
    return `${startedAt} - ${solvedAt ?? "Ongoing"}`;
  }, [pause]);

  return (
    <DialogContent className="flex flex-col gap-6 sm:max-w-xl ">
      <DialogHeader className="text-2xl font-semibold text-[#1B261B]">
        {t("are-you-sure-you-want-to-delete-process-timer-pause")}
      </DialogHeader>
      <DialogDescription>
        {t("this-pause-wont-be-visible-in-future-reports")}
      </DialogDescription>
      <div className="flex flex-col gap-4">
        <Field label={t("order")} value={pause.orderId} />
        <Field label={t("pause-start")} value={dateRange} />
        <Field label={t("reason-for-delay")} value={pause.reasonType} />
        {pause.reasonType === PauseReasonType.OTHER && (
          <Field label={t("other-reason")} value={pause.otherReason!} />
        )}
        <Field label={t("responsible-for-delay")} value={pause.responsible} />

        {pause.status === "finished" && (
          <>
            <Field
              label={t("reason-for-solution")}
              value={pause.solutionType}
            />
            {pause.solutionType === PauseSolutionType.OTHER && (
              <Field label={t("other-solution")} value={pause.otherSolution!} />
            )}
          </>
        )}
        {pause.additionalInformation && (
          <Field
            label={t("additional-information")}
            value={pause.additionalInformation}
          />
        )}
      </div>
      <span className="rounded border border-[#EE404C] bg-[#EE404C10] p-6 text-center text-sm font-medium text-[#667085]">
        {t("this-action-cant-be-undone")}
      </span>
      <div className="flex flex-col gap-4">
        <Button
          form="new-pause-form"
          type="submit"
          onClick={handleDelete}
          disabled={isPending}
        >
          {isPending && <Loader2 className="mr-2 animate-spin" />}
          {t("delete-pause")}
        </Button>
        <Button variant="secondary" onClick={handleCancel}>
          {t("cancel")}
        </Button>
      </div>
    </DialogContent>
  );
}

function Field({ label, value }: { label: string; value: string | number }) {
  return (
    <div className="flex flex-col gap-2">
      <Label className="font-bold text-[#7C8B9D]">{label}</Label>
      <span className="truncate text-[#7C8B9D]">{value}</span>
    </div>
  );
}
