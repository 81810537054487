import { atomWithReset } from "jotai/utils";
import { InboundEditDeviceModal } from "./inbound/EditDeviceModal";
import { InboundAddExtraDeviceFromOtherOrderModal } from "@/lib/modals/inbound/AddExtraDeviceFromOtherOrderModal";
import { InboundAddDeviceModal } from "@/lib/modals/inbound/AddDeviceModal";
import { InboundRemoveDeviceFromCheckInModal } from "@/lib/modals/inbound/RemoveDeviceFromCheckInModal";
import { InboundSelectCorrectDeviceModal } from "@/lib/modals/inbound/SelectCorrectDeviceModal";
import { InboundMissingDevicesModal } from "@/lib/modals/inbound/MissingDevicesModal";
import { ErrorModal } from "@/lib/modals/common/ErrorModal";
import { MissingTagModal } from "@/lib/modals/inbound/MissingTagModal";
import { ManualTrackingNumberModal } from "@/lib/modals/common/ManualTrackingNumberModal";
import { InboundPrintModal } from "@/lib/modals/inbound/PrintModal";
import { CreateEndCustomerModal } from "@/lib/modals/endCustomer/CreateEndCustomerModal";
import { DeleteEndCustomerModal } from "@/lib/modals/endCustomer/DeleteEndCustomerModal";
import { CreatePartnerModal } from "@/lib/modals/partner/CreatePartnerModal";
import { ChangeDeviceStateModal } from "@/lib/modals/common/ChangeDeviceStateModal";
import { InspectDeviceSearchModal } from "@/lib/modals/inbound/DeviceSearchModal";
import { CreateProductCodeModal } from "@/lib/modals/common/CreateProductCodeModal";
import { ConfirmModal } from "@/lib/modals/common/ConfirmModal";
import { InspectSetStorageNameModal } from "@/lib/modals/inspect/InspectSetStorageNameModal";
import { AddNewPauseModal } from "@/lib/modals/pauses/AddNewPauseModal";
import { RemovePauseModal } from "@/lib/modals/pauses/RemovePauseModal";
import { CopyPauseModal } from "@/lib/modals/pauses/CopyPauseModal";
import { EditPauseModal } from "@/lib/modals/pauses/EditPauseModal";
import { EndPauseModal } from "@/lib/modals/pauses/EndPauseModal";
import { PreviewPauseModal } from "@/lib/modals/pauses/PreviewPauseModal";

export const MODAL_COMPONENTS = {
  InboundEditDevice: InboundEditDeviceModal,
  InboundAddDevice: InboundAddDeviceModal,
  InboundAddExtraDeviceFromOtherOrder: InboundAddExtraDeviceFromOtherOrderModal,
  InboundRemoveDeviceFromCheckIn: InboundRemoveDeviceFromCheckInModal,
  InboundSelectCorrectDevice: InboundSelectCorrectDeviceModal,
  MissingDevices: InboundMissingDevicesModal,
  InspectDeviceSearch: InspectDeviceSearchModal,
  InspectSetStorageName: InspectSetStorageNameModal,
  Error: ErrorModal,
  MissingTag: MissingTagModal,
  ManualTrackingNumber: ManualTrackingNumberModal,
  InboundPrint: InboundPrintModal,
  CreateEndCustomer: CreateEndCustomerModal,
  DeleteEndCustomer: DeleteEndCustomerModal,
  CreatePartner: CreatePartnerModal,
  ChangeDeviceState: ChangeDeviceStateModal,
  CreateProductCode: CreateProductCodeModal,
  Confirm: ConfirmModal,
  AddNewPause: AddNewPauseModal,
  RemovePause: RemovePauseModal,
  EditPause: EditPauseModal,
  CopyPause: CopyPauseModal,
  EndPause: EndPauseModal,
  PreviewPause: PreviewPauseModal,
} as const;

export type ModalType = keyof typeof MODAL_COMPONENTS;
export type ModalProps<T extends keyof typeof MODAL_COMPONENTS> =
  BaseModalProps & Parameters<(typeof MODAL_COMPONENTS)[T]>[0];

export interface BaseModalProps {
  disableClose?: boolean;
}

export interface ModalState<T extends ModalType = ModalType> {
  modalType?: T;
  modalProps?: ModalProps<T>;
  modalResolve?: () => void;
}

export const modalStateAtom = atomWithReset<ModalState>({
  modalType: undefined,
  modalProps: undefined,
  modalResolve: undefined,
});
