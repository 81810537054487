import { FormRadioGroupField } from "@/components/form/RadioGroupField";
import { Button } from "@/components/ui/button";
import { DialogContent, DialogHeader } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { useMutateCopyPause } from "@/lib/hooks/query/pause/useMutateCopyPause";
import { CopyModalOrderTable } from "@/lib/modals/pauses/CopyPauseModal/CopyModalOrderTable";
import { OrderSearchField } from "@/lib/modals/pauses/CopyPauseModal/CopyModalSearchField";
import { CopyFormState } from "@/lib/modals/pauses/CopyPauseModal/types";
import { pauseUpsertSchema } from "@/lib/modals/pauses/pauseFormSchema";
import { useModal } from "@/lib/modals/useModal";
import {
  ParsedPause,
  PauseReasonType,
  PauseSolutionType,
} from "@/lib/types/pause";
import { Loader2 } from "lucide-react";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface CopyPauseModalProps {
  pause: ParsedPause;
}

export function CopyPauseModal({ pause }: CopyPauseModalProps) {
  const { t } = useTranslation("", { keyPrefix: "common.modal.copy-pause" });
  const { t: tResponsible } = useTranslation("", {
    keyPrefix: "common.pause-responsible",
  });
  const { t: tReason } = useTranslation("", {
    keyPrefix: "common.pause-reason",
  });
  const { t: tSolution } = useTranslation("", {
    keyPrefix: "common.pause-solution",
  });
  const { hideModal } = useModal();

  const { mutateAsync, isPending } = useMutateCopyPause();

  const form = useForm<CopyFormState>({
    mode: "onChange",
    defaultValues: {
      status: "ongoing",
      orders: [],
    },
  });

  const orders = form.watch("orders");

  const handleSubmit = form.handleSubmit(async (values, event) => {
    event?.preventDefault();
    await mutateAsync({
      orderIdList: values.orders.map((o) => o.id),
      pause: pauseUpsertSchema.parse(pause),
      copySolution: values.status === "finished",
    });
    hideModal();
  });

  const handleCancel = () => {
    hideModal();
  };

  const dateRange = useMemo(() => {
    const startedAt = pause.startedAt.toLocaleDateString("fi-en");
    const solvedAt =
      pause.status === "finished"
        ? pause.solvedAt.toLocaleDateString("fi-en")
        : null;
    return `${startedAt} - ${solvedAt ?? "Ongoing"}`;
  }, [pause]);

  const reaseonValue = useMemo(() => {
    const reasonTranslated = tReason(pause.reasonType, pause.reasonType);
    if (pause.reasonType === PauseReasonType.OTHER) {
      return `${reasonTranslated} - ${pause.otherReason}`;
    }
    return reasonTranslated;
  }, [pause, tReason]);

  const solutionValue = useMemo(() => {
    if (pause.status === "ongoing") return "";
    const solutionTranslated = tSolution(
      pause.solutionType,
      pause.solutionType,
    );
    if (pause.solutionType === PauseSolutionType.OTHER) {
      return `${solutionTranslated} - ${pause.otherSolution}`;
    }
    return solutionTranslated;
  }, [pause, tSolution]);

  return (
    <DialogContent className="flex max-h-[90dvh] flex-col gap-6 sm:max-w-5xl">
      <DialogHeader className="text-2xl font-semibold text-[#1B261B]">
        {t("copy-to-another-order")}
      </DialogHeader>
      <FormProvider {...form}>
        <form
          id="new-pause-form"
          onSubmit={handleSubmit}
          className="flex max-h-full flex-col gap-4 overflow-hidden p-1"
        >
          <div className="grid grid-cols-2 gap-y-4">
            <div className="flex flex-col gap-4">
              <Field label={t("process-timer-pause-range")} value={dateRange} />
              <Field
                label={t("responsible-for-delay")}
                value={tResponsible(pause.responsible, pause.responsible)}
              />
            </div>
            <div className="flex flex-col gap-4">
              <Field label={t("reason-for-delay")} value={reaseonValue} />
              {pause.status === "finished" && (
                <Field label={t("solution")} value={solutionValue} />
              )}
            </div>
            <div className="col-span-2">
              {pause.additionalInformation && (
                <Field
                  label={t("additional-information")}
                  value={pause.additionalInformation}
                />
              )}
            </div>
          </div>
          <FormRadioGroupField
            name="status"
            label="What should be copied"
            options={[
              { value: "ongoing", label: t("process-time-pause") },
              {
                value: "finished",
                label: t("process-time-pause-and-solution"),
                disabled: pause.status !== "finished",
              },
            ]}
          />
          <OrderSearchField sourceOrderId={pause.orderId} />
          <CopyModalOrderTable />
        </form>
      </FormProvider>
      <div className="grid grid-cols-2 gap-2">
        <Button variant="secondary" onClick={handleCancel}>
          {t("cancel")}
        </Button>
        <Button
          form="new-pause-form"
          type="submit"
          disabled={isPending || orders.length === 0}
        >
          {isPending && <Loader2 className="mr-2 animate-spin" />}
          {t("copy-pauses")}
        </Button>
      </div>
    </DialogContent>
  );
}

function Field({ label, value }: { label: string; value: string | number }) {
  return (
    <div className="flex flex-col gap-2">
      <Label className="font-bold text-[#7C8B9D]">{label}</Label>
      <span className="text-[#7C8B9D]">{value}</span>
    </div>
  );
}
