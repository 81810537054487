import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { Asset } from "@/api/generated/data-contracts";
import { apiClient } from "@/api";

type QueryData = Asset[];
type QueryKey = ["device-by-serial", string | undefined];

export function useQueryDevicesBySerialNumber<TData = QueryData>(
  serialNumber: string | undefined,
  options?: Omit<
    UseQueryOptions<QueryData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >,
) {
  return useQuery<QueryData, unknown, TData, QueryKey>({
    ...options,
    queryKey: ["device-by-serial", serialNumber],
    queryFn: async () => {
      if (!serialNumber) return [];
      const response = await apiClient.assetsBySerialNumberV2List({
        serialNumber,
      });
      if (!response.ok) {
        throw new Error("Failed to fetch device by serial number");
      }
      return response.data;
    },
    retry: false,
  });
}
