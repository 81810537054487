import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { Asset } from "@/api/generated/data-contracts";
import { apiClient } from "@/api";

interface MutationVariables {
  serialNumber: string;
}

export function useMutationGetDeviceBySerialNumber(
  options?: Omit<
    UseMutationOptions<Asset[], unknown, MutationVariables>,
    "mutationFn"
  >,
) {
  return useMutation<Asset[], unknown, MutationVariables>({
    ...options,
    mutationFn: async ({ serialNumber }) => {
      try {
        const response = await apiClient
          .assetsBySerialNumberV2List({
            serialNumber,
          })
          .catch();
        if (!response.ok) {
          throw new Error("Failed to get device by serial number");
        }

        return response.data;
      } catch (error) {
        if (error instanceof Response && error.status === 404)
          return [] as Asset[];
        throw error;
      }
    },
  });
}
