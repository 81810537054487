import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { HttpResponse } from "@/api/generated/http-client";
import { toast } from "sonner";
import { PauseUpsertValues } from "@/lib/modals/pauses/pauseFormSchema";
import { ParsedPause, parsePause } from "@/lib/types/pause";
import { apiClient } from "@/api";
import { PauseUpsertion, Solution } from "@/api/generated/data-contracts";
import { formatISO } from "date-fns";

interface CopyResult {
  success: ParsedPause[];
  failed: PromiseRejectedResult[];
}

interface MutationParams {
  orderIdList: number[];
  pause: PauseUpsertValues;
  copySolution?: boolean;
}

export function useMutateCopyPause(
  options?: Omit<
    UseMutationOptions<CopyResult, HttpResponse<void, Error>, MutationParams>,
    "mutationFn"
  >,
) {
  const queryClient = useQueryClient();
  return useMutation<CopyResult, HttpResponse<void, Error>, MutationParams>({
    ...options,
    mutationFn: async ({ orderIdList, pause, copySolution }) => {
      const solution: Solution | undefined =
        copySolution && pause.status === "finished"
          ? {
              solvedAt: formatISO(pause.solvedAt).substring(0, 10),
              solutionType: pause.solutionType,
              otherSolution: pause.otherSolution ?? null,
            }
          : undefined;
      const newPause: PauseUpsertion = {
        startedAt: formatISO(pause.startedAt).substring(0, 10),
        additionalInformation: pause.additionalInformation || null,
        reasonType: pause.reasonType,
        responsible: pause.responsible,
        otherReason: pause.otherReason ?? null,
        solution: solution,
      };

      const result = await Promise.allSettled(
        orderIdList.map(async (orderId) => {
          const response = await apiClient.ordersPausesCreate(
            orderId,
            newPause,
          );
          return response.data;
        }),
      );

      const success = result
        .filter((r) => r.status === "fulfilled")
        .map((r) => parsePause(r.value));
      const failed = result.filter((r) => r.status === "rejected");

      return {
        success,
        failed,
      };
    },
    onSuccess: ({ success, failed }, { orderIdList }) => {
      toast.success(
        `${success.length} pause(s) copied` +
          (failed.length > 0 ? `, and ${failed.length} failed` : ""),
      );
      orderIdList.forEach((orderId) => {
        queryClient.invalidateQueries({ queryKey: ["pauses", orderId] });
      });
    },
    onError: () => {
      toast.error("Failed to copy pause");
    },
  });
}
