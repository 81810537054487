import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { HttpResponse } from "@/api/generated/http-client";
import { toast } from "sonner";
import { PauseUpsertValues } from "@/lib/modals/pauses/pauseFormSchema";
import { ParsedPause, parsePause } from "@/lib/types/pause";
import { apiClient } from "@/api";
import { Solution } from "@/api/generated/data-contracts";
import { formatISO } from "date-fns";

type MutationParams = {
  orderId: number;
  pause: PauseUpsertValues;
};

export function useMutateCreatePause(
  options?: Omit<
    UseMutationOptions<ParsedPause, HttpResponse<void, Error>, MutationParams>,
    "mutationFn"
  >,
) {
  const queryClient = useQueryClient();
  return useMutation<ParsedPause, HttpResponse<void, Error>, MutationParams>({
    ...options,
    mutationFn: async ({ orderId, pause }) => {
      const solution: Solution | undefined =
        pause.status === "finished"
          ? {
              solvedAt: formatISO(pause.solvedAt).substring(0, 10),
              solutionType: pause.solutionType,
              otherSolution: pause.otherSolution ?? null,
            }
          : undefined;
      const response = await apiClient.ordersPausesCreate(orderId, {
        startedAt: formatISO(pause.startedAt).substring(0, 10),
        additionalInformation: pause.additionalInformation || null,
        reasonType: pause.reasonType,
        responsible: pause.responsible,
        otherReason: pause.otherReason ?? null,
        solution: solution,
      });
      return parsePause(response.data);
    },
    onSuccess: (pause) => {
      toast.success("Pause created");
      queryClient.invalidateQueries({ queryKey: ["pauses", pause.orderId] });
    },
    onError: () => {
      toast.error("Failed to create pause");
    },
  });
}
