import {
  Navigate,
  Outlet,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import * as Sentry from "@sentry/react";
import { ErrorBoundaryFallback } from "@/views/ErrorView";
import { BaseLayout } from "@/layouts/BaseLayout";

const ProtectedRoute = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <BaseLayout>
          <Outlet />
        </BaseLayout>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Navigate to="/login" />
      </UnauthenticatedTemplate>
    </>
  );
};
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: (
      <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{ updateType: "replaceIn" }}
        >
          <Outlet />
        </QueryParamProvider>
      </Sentry.ErrorBoundary>
    ),
    children: [
      {
        path: "/login",
        lazy: () => import("@/views/LoginView"),
      },
      {
        path: "/logout",
        lazy: () => import("@/views/LogoutView"),
      },
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: "/",
            loader: () => redirect("/monitoring"),
          },
          {
            path: "/inbound",
            lazy: () => import("@/views/inbound/InboundView"),
          },
          {
            path: "/cleanup",
            lazy: () => import("@/views/cleanup/CleanupView"),
          },
          {
            path: "/inspect",
            lazy: () => import("@/views/inspect/InspectView"),
          },
          {
            path: "/interim-storage",
            lazy: () => import("@/views/interimStorage/InterimStorageView"),
          },
          {
            path: "/client-depot",
            lazy: () => import("@/views/client-depot/ClientDepotView"),
          },
          {
            path: "/monitoring",
            children: [
              {
                index: true,
                element: <Navigate to="order" replace />,
              },
              {
                path: "device",
                lazy: () =>
                  import("@/views/monitoring/MonitoringDeviceListView"),
              },
              {
                path: "device/:deviceId",
                lazy: () => import("@/views/monitoring/device/DeviceView"),
              },
              {
                path: "order",
                lazy: () =>
                  import("@/views/monitoring/MonitoringOrderListView"),
              },
              {
                path: "order/:orderId",
                lazy: () => import("@/views/monitoring/order/OrderView"),
              },
            ],
          },
          {
            path: "/customer-management",
            element: <Navigate to="/customer-management/partner" replace />,
          },
          {
            path: "/customer-management/partner",
            lazy: () => import("@/views/customerManagement/PartnerListView"),
          },
          {
            path: "/customer-management/partner/:partnerId",
            lazy: () => import("@/views/customerManagement/PartnerView"),
          },
          {
            path: "/customer-management/end-customer",
            lazy: () =>
              import("@/views/customerManagement/EndCustomerListView"),
          },
          {
            path: "/customer-management/end-customer/:endCustomerId",
            lazy: () => import("@/views/customerManagement/EndCustomerView"),
          },
        ],
      },
    ],
  },
]);
