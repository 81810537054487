import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { HttpResponse } from "@/api/generated/http-client";
import { toast } from "sonner";
import { apiClient } from "@/api";
import { ParsedPause, parsePause } from "@/lib/types/pause";
import { PauseUpsertValues } from "@/lib/modals/pauses/pauseFormSchema";
import { Solution } from "@/api/generated/data-contracts";
import { formatISO } from "date-fns";

type MutationParams = {
  orderId: number;
  pauseId: string;
  pause: PauseUpsertValues;
};

export function useMutateUpdatePause(
  options?: Omit<
    UseMutationOptions<ParsedPause, HttpResponse<void, Error>, MutationParams>,
    "mutationFn"
  > & { successToast?: string; errorToast?: string },
) {
  const queryClient = useQueryClient();
  return useMutation<ParsedPause, HttpResponse<void, Error>, MutationParams>({
    ...options,
    mutationFn: async ({ pauseId, pause }) => {
      const solution: Solution | undefined =
        pause.status === "finished"
          ? {
              otherSolution: pause.otherSolution || null,
              solutionType: pause.solutionType,
              solvedAt: formatISO(pause.solvedAt).substring(0, 10),
            }
          : undefined;
      const response = await apiClient.pausesUpdate(pauseId, {
        startedAt: formatISO(pause.startedAt).substring(0, 10),
        additionalInformation: pause.additionalInformation || null,
        reasonType: pause.reasonType,
        responsible: pause.responsible,
        otherReason: pause.otherReason || null,
        solution: solution,
      });
      return parsePause(response.data);
    },
    onSuccess: (pause) => {
      toast.success(options?.successToast ?? "Pause updated");
      queryClient.invalidateQueries({ queryKey: ["pauses", pause.orderId] });
      queryClient.invalidateQueries({ queryKey: ["pauseWithOrder"] });
      queryClient.invalidateQueries({ queryKey: ["pause", "log", pause.id] });
    },
    onError: (error) => {
      console.error(error);
      toast.error(options?.errorToast ?? "Failed to update pause");
    },
  });
}
