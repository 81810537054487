import { OrderV2 } from "@/api/generated/data-contracts";
import { DataTable } from "@/components/DataTable";
import { Button } from "@/components/ui/button";
import { CopyFormState } from "@/lib/modals/pauses/CopyPauseModal/types";
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
} from "@tanstack/react-table";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export function CopyModalOrderTable() {
  const { t } = useTranslation("", { keyPrefix: "common.modal.copy-pause" });
  const form = useFormContext<CopyFormState>();

  const orders = form.watch("orders");

  const handleRemove = useCallback(
    (id: number) => {
      form.setValue(
        "orders",
        orders.filter((o: OrderV2) => o.id !== id),
      );
    },
    [form, orders],
  );

  const columns = useMemo<ColumnDef<OrderV2>[]>(
    () => [
      {
        header: t("order-id"),
        accessorKey: "id",
      },
      {
        header: t("created-at"),
        accessorFn: (row) =>
          new Date(row.orderCreateDate).toLocaleDateString("fi-Fi"),
      },
      {
        header: t("partner-client"),
        id: "partner-client",
        accessorFn: (row) => `${row.partnerName}/${row.endCustomerName}`,
      },
      {
        header: t("devices"),
        id: "devices",
        accessorFn: (row) => row.assets?.length ?? 0,
      },
      {
        id: "actions",
        cell: ({ row }) => {
          return (
            <Button
              variant="secondary"
              className="float-end"
              onClick={() => handleRemove(row.original.id)}
            >
              {t("remove")}
            </Button>
          );
        },
      },
    ],
    [handleRemove, t],
  );

  const table = useReactTable({
    columns,
    data: orders,
    getCoreRowModel: getCoreRowModel(),
  });

  if (orders.length === 0) {
    return (
      <span className="py-8 text-center text-[#7C8B9D]">
        {t("add-destination-orders-by-searching-with-order-number")}
      </span>
    );
  }

  return (
    <div className="max-hh-96 overflow-y-auto">
      <DataTable table={table} />
    </div>
  );
}
