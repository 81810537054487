import { useAtom, useAtomValue } from "jotai";
import { FieldPath, useForm, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDebounce } from "@uidotdev/usehooks";
import * as z from "zod";

import { Button } from "@/components/ui/button";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useQueryDeviceCategories } from "@/lib/hooks/query/device/useQueryDeviceCategories";
import {
  ScannedDevice,
  scannedDevicesAtom,
  targetOrderIdAtom,
} from "@/views/inbound/inboundState";
import { useModal } from "@/lib/modals/useModal";
import { useMutationCheckInDevice } from "@/lib/hooks/query/device/useMutationCheckInDevice";
import { useTranslation } from "react-i18next";
import { useMutationUpdateDevice } from "@/lib/hooks/query/device/useMutateDevice";
import { Asset, CreateAsset } from "@/api/generated/data-contracts";

export const FormDataSchema = z.object({
  categoryId: z.string().min(1).regex(/^\d+$/),
  model: z.string().min(1),
  manufacturer: z.string().min(1),
  serialNumber: z.string(),
  extraItems: z.string(),
});

type FormData = z.infer<typeof FormDataSchema>;

export function InboundEditDeviceModal({
  device,
}: Readonly<{ device: ScannedDevice }>) {
  const { t } = useTranslation("", { keyPrefix: "inbound.modal.edit-device" });
  const { hideModal } = useModal();
  const [devices, setDevices] = useAtom(scannedDevicesAtom);

  const targetOrderId = useAtomValue(targetOrderIdAtom);

  const checkInMutation = useMutationCheckInDevice();
  const updateDeviceMutation = useMutationUpdateDevice();

  const form = useForm<FormData>({
    resolver: zodResolver(FormDataSchema),
    defaultValues: {
      categoryId: device?.classificationCategoryId?.toString() ?? "",
      model: device?.productName ?? "",
      manufacturer: device?.manufacturer ?? "",
      serialNumber: device?.readSerialNumber ?? "",
      extraItems: device?.extraItems ?? "",
    },
  });

  const handleSubmitLocal = async (values: FormData) => {
    const updatedDevice: ScannedDevice = {
      ...device,
      classificationCategoryId: parseInt(values.categoryId),
      orderId: targetOrderId,
      productName: values.model,
      manufacturer: values.manufacturer,
      readSerialNumber: values.serialNumber,
      extraItems: values.extraItems,
      state: "success",
    };
    const updatedAsset: CreateAsset = {
      readSerialNumber: values.serialNumber,
      productName: values.model,
      manufacturer: values.manufacturer,
      classificationCategoryId: parseInt(values.categoryId),
      additionalInfo: values.extraItems,
    };

    let newDevice: Asset;
    if (updatedDevice.eolTrackingNumber) {
      newDevice = await updateDeviceMutation.mutateAsync({
        trackingNumber: updatedDevice.eolTrackingNumber,
        device: updatedAsset,
      });
    } else {
      newDevice = await checkInMutation.mutateAsync({
        device: updatedDevice,
      });
      updatedDevice.isExtra = newDevice.eolBatchItemTypeId !== 1;
    }

    updatedDevice.eolTrackingNumber = newDevice.eolTrackingNumber!;

    if (devices.some((d) => d === device)) {
      setDevices(devices.map((d) => (d === device ? updatedDevice : d)));
    } else {
      setDevices([...devices, updatedDevice]);
    }
    hideModal();
  };

  return (
    <DialogContent className="sm:max-w-md">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmitLocal)}
          className="flex flex-col gap-6"
        >
          <DialogHeader>
            <DialogTitle>{t("edit-device")}</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-6">
            <DeviceCategorySelect orderId={targetOrderId} />
            <TextField label={t("brand")} name="manufacturer" />
            <TextField label={t("model")} name="model" />
            <TextField label={t("serial")} name="serialNumber" />
            <TextField
              label={t("additional-information-optional")}
              name="extraItems"
            />
          </div>
          <DialogFooter className="sm:justify-start">
            <Button
              className="w-full"
              variant="secondary"
              onClick={(e) => {
                e.preventDefault();
                hideModal();
              }}
            >
              {t("close")}
            </Button>
            <Button
              type="submit"
              className="w-full"
              disabled={!form.formState.isValid}
            >
              {t("save")}
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </DialogContent>
  );
}

function TextField<TName extends FieldPath<FormData> = FieldPath<FormData>>({
  label,
  name,
}: Readonly<{
  label: string;
  name: TName;
}>) {
  const form = useFormContext<FormData>();
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input {...field} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export const DeviceCategorySelect = ({
  orderId,
}: {
  orderId: number | undefined;
}) => {
  const { t } = useTranslation("", { keyPrefix: "inbound.modal.edit-device" });
  const form = useFormContext<FormData>();

  const debouncedOrderId = useDebounce(orderId, 500);
  const deviceCategoriesQuery = useQueryDeviceCategories(debouncedOrderId, {
    enabled: !!orderId,
    select: (data) =>
      data?.map((item) => ({ id: item.id.toString(), name: item.name })) ?? [],
  });

  let deviceCategoryPlaceholder = t("select-device-category");
  if (deviceCategoriesQuery.isPending) deviceCategoryPlaceholder = t("loading");

  return (
    <FormField
      control={form.control}
      name="categoryId"
      render={({ field }) => (
        <FormItem>
          <FormLabel>{t("device-category")}</FormLabel>
          <Select
            onValueChange={field.onChange}
            value={field.value}
            disabled={!orderId}
          >
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder={deviceCategoryPlaceholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {deviceCategoriesQuery.data?.map((deviceCategory) => (
                <SelectItem
                  key={deviceCategory.id}
                  value={deviceCategory.id.toString()}
                >
                  {deviceCategory.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
