import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { apiClient } from "@/api";
import { PauseEditLogItem } from "@/api/generated/data-contracts";

type QueryFnData = PauseEditLogItem[];
type QueryKey = ["pause", "log", string | undefined];

export function useQueryPauseLog<TData = QueryFnData>(
  pauseId: string | undefined,
  options?: Omit<
    UseQueryOptions<QueryFnData, Error, TData, QueryKey>,
    "queryKey" | "queryFn"
  >,
) {
  return useQuery<QueryFnData, Error, TData, QueryKey>({
    staleTime: 1000 * 60 * 5, // 5 minutes
    ...options,
    queryKey: ["pause", "log", pauseId],
    queryFn: async () => {
      const response = await apiClient.pausesEditLogsDetail(pauseId!);
      return response.data;
    },
    enabled: !!pauseId && (options?.enabled ?? true),
  });
}
