import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useFormContext } from "react-hook-form";

export interface FormInputFieldProps {
  name: string;
  label?: string;
  placeholder?: string;
  optional?: boolean;
  max?: number;
  disabled?: boolean;
}

export function FormInputField({
  name,
  label,
  placeholder,
  optional,
  max,
  disabled,
}: FormInputFieldProps) {
  const form = useFormContext();
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {label && (
            <FormLabel>
              {label}{" "}
              {optional && <span className="text-[#7C8B9D]">(optional)</span>}
            </FormLabel>
          )}
          <FormControl>
            <Input
              placeholder={placeholder}
              {...field}
              value={field.value ?? ""}
              disabled={disabled}
            />
          </FormControl>
          {max && (
            <FormDescription className="text-[#7C8B9D]">
              {field.value?.length ?? 0}/{max} characters
            </FormDescription>
          )}

          <FormMessage />
        </FormItem>
      )}
    />
  );
}
