import { DialogContent } from "@/components/ui/dialog";
import { EOLBatchItem } from "@/api/generated/data-contracts";
import { Button } from "@/components/ui/button";
import { useModal } from "@/lib/modals/useModal";
import { useTranslation } from "react-i18next";
import { useMutationOrderMissingDevices } from "@/lib/hooks/query/order/useMutateOrderMissingDevices";
import { AlertOctagon, Loader2Icon } from "lucide-react";
import { useWizard, Wizard } from "react-use-wizard";
import { Separator } from "@/components/ui/separator";
import { useCallback } from "react";

export interface InboundMissingDevicesModalProps {
  orderId: number;
  assets: EOLBatchItem[];
  onCancel: () => void;
}

export function InboundMissingDevicesModal({
  orderId,
  assets,
  onCancel,
}: Readonly<InboundMissingDevicesModalProps>) {
  const totalDevices = assets.filter((a) => a.stateId && a.stateId >= 120);
  const inboundDevices = totalDevices.filter((a) => !!a.eolTrackingNumber);
  const missingDevicesCount = totalDevices.length - inboundDevices.length;

  return (
    <DialogContent className="flex flex-col gap-0 px-0 sm:max-w-lg">
      <Wizard>
        <MissingDeviceSummary
          orderId={orderId}
          inboundCount={inboundDevices.length}
          totalCount={totalDevices.length}
          onCancel={onCancel}
        />
        <MissingDeviceConfirmation
          orderId={orderId}
          missingDevicesCount={missingDevicesCount}
        />
      </Wizard>
    </DialogContent>
  );
}

function Gauge({ value, total }: { value: number; total: number }) {
  const { t } = useTranslation("", {
    keyPrefix: "inbound.modal.missing-devices",
  });
  const missing = total - value;
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row gap-1 text-2xl font-semibold">
        <span
          style={{ flexGrow: value }}
          className="min-w-min rounded bg-[#D0D5DD] px-8 py-3 text-center text-[#7C8B9D]"
        >
          {value}
        </span>
        <span
          style={{ flexGrow: missing }}
          className="min-w-min rounded bg-[#EE404C] px-8 py-3 text-center text-white"
        >
          {missing}
        </span>
      </div>
      <div className="flex w-full gap-4 text-sm font-medium text-[#7C8B9D]">
        <span className="flex flex-row items-center gap-1 before:block before:size-3 before:rounded before:bg-[#D0D5DD] before:content-['']">
          {t("inbound")}
        </span>
        <span className="flex flex-row items-center gap-1 before:block before:size-3 before:rounded before:bg-[#EE404C] before:content-['']">
          {t("not-inbound")}
        </span>
        <span className="ml-auto">
          {value}/{total}
        </span>
      </div>
    </div>
  );
}

function MissingDeviceSummary({
  inboundCount,
  totalCount,
  orderId,
  onCancel,
}: {
  inboundCount: number;
  totalCount: number;
  orderId: number;
  onCancel: () => void;
}) {
  const { t } = useTranslation("", {
    keyPrefix: "inbound.modal.missing-devices",
  });

  const { hideModal } = useModal();
  const { nextStep } = useWizard();

  const missingCount = totalCount - inboundCount;

  const handleCancel = useCallback(() => {
    onCancel();
    hideModal();
  }, [onCancel, hideModal]);

  return (
    <>
      <div className="flex flex-col gap-6 px-12">
        <div className="flex flex-col gap-1">
          <span className="text-2xl font-semibold text-[#1E1B26]">
            {t("order-inbound-incomplete")}
          </span>
          <span className="text-sm text-[#7C8B9D]">
            {t("order-id", { orderId })}
          </span>
        </div>
        <Gauge value={inboundCount} total={totalCount} />
      </div>
      <Separator className="my-12" />
      <div className="flex flex-col gap-6 px-12">
        <span className="text-xl font-semibold text-[#1E1B26]">
          {t("where-are-the-missing-devices", { count: missingCount })}
        </span>
        <div className="flex w-full flex-col gap-2">
          <Button onClick={hideModal}>
            {t("devices-will-be-inbound-later")}
          </Button>
          <Button variant="secondary" onClick={nextStep}>
            {t("devices-are-missing")}
          </Button>
          <Button variant="secondary" onClick={handleCancel}>
            {t("cancel")}
          </Button>
        </div>
      </div>
    </>
  );
}

function MissingDeviceConfirmation({
  orderId,
  missingDevicesCount,
}: {
  orderId: number;
  missingDevicesCount: number;
}) {
  const { t } = useTranslation("", {
    keyPrefix: "inbound.modal.missing-devices",
  });
  const { hideModal } = useModal();

  const { previousStep } = useWizard();

  const mutationMissingDevices = useMutationOrderMissingDevices();

  const handleMarkMissing = async () => {
    await mutationMissingDevices.mutateAsync({ orderId });
    hideModal();
  };

  return (
    <div className="flex flex-col gap-6 px-12">
      <AlertOctagon className="size-12 self-center" />
      <h2 className="text-2xl font-semibold text-[#1E1B26]">
        {t("confirm-missing-devices")}
      </h2>
      <div className="flex flex-col gap-3 text-sm text-[#1E1B26]">
        <span>
          {t("are-you-sure-you-want-to-mark-devices", {
            orderId,
            count: missingDevicesCount,
          })}
        </span>
        <span>{t("before-you-mark-devices-as-missing")}</span>
        <ol className="list-inside list-decimal">
          <li>{t("task-list-1")}</li>
          <li>{t("task-list-2")}</li>
          <li>{t("task-list-3")}</li>
        </ol>
        <span>{t("if-you-are-unsure-ask-your-supervisor")}</span>
      </div>

      <span className="rounded border-2 border-[#EE404C] bg-[#EE404C0A] p-6 text-center text-sm font-bold text-[#EE404C]">
        {t("this-action-can-t-be-undone")}
      </span>

      <div className="flex w-full flex-col gap-2">
        <Button
          onClick={handleMarkMissing}
          disabled={mutationMissingDevices.isPending}
          className="flex items-center justify-center gap-2"
        >
          {mutationMissingDevices.isPending && (
            <Loader2Icon className="size-4 animate-spin" />
          )}
          {t("mark-devices-as-missing")}
        </Button>
        <Button
          variant="secondary"
          onClick={previousStep}
          disabled={mutationMissingDevices.isPending}
        >
          {t("cancel")}
        </Button>
      </div>
    </div>
  );
}
