import { FormDateField } from "@/components/form/DateField";
import { FormInputField } from "@/components/form/InputField";
import { FormSelectField, SelectOption } from "@/components/form/SelectField";
import { FormTextAreaField } from "@/components/form/TextAreaField";
import { Button } from "@/components/ui/button";
import { DialogContent, DialogHeader } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { useMutateUpdatePause } from "@/lib/hooks/query/pause/useMutateUpdatePause";
import {
  finishedPauseUpsertSchema,
  FinishedPauseUpsertValues,
} from "@/lib/modals/pauses/pauseFormSchema";
import { useModal } from "@/lib/modals/useModal";
import {
  ParsedPause,
  PauseReasonType,
  PauseResponsible,
  pauseSolutionFilteredOptions,
  PauseSolutionType,
} from "@/lib/types/pause";
import { cn } from "@/lib/utils";

import { zodResolver } from "@hookform/resolvers/zod";
import { isBefore } from "date-fns";
import { atom, useAtom, useAtomValue } from "jotai";
import { EditIcon, Loader2 } from "lucide-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Matcher } from "react-day-picker";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const isEditingAtom = atom(false);

export function EndPauseModal({ pause }: { pause: ParsedPause }) {
  const { t } = useTranslation("", { keyPrefix: "common.modal.end-pause" });
  const { hideModal } = useModal();

  const [isEditing, setIsEditing] = useAtom(isEditingAtom);

  const { mutateAsync, isPending } = useMutateUpdatePause({
    successToast: "Pause ended successfully",
    errorToast: "Failed to end pause",
  });

  const form = useForm<FinishedPauseUpsertValues>({
    resolver: zodResolver(finishedPauseUpsertSchema),
    mode: "onBlur",
    defaultValues: {
      status: "finished",
      startedAt: pause.startedAt,
      reasonType: pause.reasonType,
      otherReason: pause.otherReason,
      responsible: pause.responsible,
      additionalInformation: pause.additionalInformation ?? "",
      solvedAt: isBefore(pause.startedAt, new Date()) ? new Date() : undefined,
    } as FinishedPauseUpsertValues,
  });

  const handleSubmit = form.handleSubmit(async (values, event) => {
    event?.preventDefault();
    await mutateAsync({
      orderId: pause.orderId,
      pauseId: pause.id,
      pause: values,
    });
    hideModal();
  });

  const handleCancel = () => {
    setIsEditing(false);
    hideModal();
  };

  return (
    <DialogContent className="top-5 flex max-h-[97dvh] translate-y-0 flex-col gap-6 sm:max-w-5xl">
      <FormProvider {...form}>
        <form
          id="new-pause-form"
          onSubmit={(e) => e.preventDefault()}
          className="h-full overflow-y-auto"
        >
          <DialogHeader className="text-2xl font-semibold text-[#1B261B]">
            {t("end-process-timer-pause")}
          </DialogHeader>

          <div className="flex flex-col gap-16 py-16">
            {isEditing ? <OngoingPauseEdit /> : <OngoingPausePreview />}
            <PauseEndFields />
          </div>

          <div className="grid grid-cols-2 gap-2">
            <Button variant="secondary" type="button" onClick={handleCancel}>
              {t("cancel")}
            </Button>
            <Button
              form="new-pause-form"
              type="button"
              onClick={handleSubmit}
              disabled={isPending || isEditing}
            >
              {isPending && <Loader2 className="mr-2 animate-spin" />}
              {t("end-pause")}
            </Button>
          </div>
        </form>
      </FormProvider>
    </DialogContent>
  );
}

function OngoingPausePreview() {
  const { t } = useTranslation("", { keyPrefix: "common.modal.end-pause" });
  const { t: tResponsible } = useTranslation("", {
    keyPrefix: "common.pause-responsible",
  });
  const { t: tReason } = useTranslation("", {
    keyPrefix: "common.pause-reason",
  });
  const [, setIsEditing] = useAtom(isEditingAtom);

  const form = useFormContext<FinishedPauseUpsertValues>();

  const handleStartEditing = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      setIsEditing(true);
    },
    [setIsEditing],
  );

  const startedAtValue = form.watch("startedAt");
  const reasonValue = form.watch("reasonType");
  const otherReasonValue = form.watch("otherReason");
  const responsibleValue = form.watch("responsible");
  const additionalInformationValue = form.watch("additionalInformation");

  const reasonFormatted = useMemo(() => {
    const reasonTranslated = tReason(reasonValue, reasonValue);
    if (reasonValue === PauseReasonType.OTHER) {
      return `${reasonTranslated} - ${otherReasonValue}`;
    }
    return reasonTranslated;
  }, [otherReasonValue, reasonValue, tReason]);

  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <h3 className="font-bold text-[#7C8B9D]">{t("details")}</h3>
        <Button
          variant="secondary"
          type="button"
          className="flex flex-row items-center gap-2"
          onClick={handleStartEditing}
        >
          <EditIcon className="size-5" />
          {t("edit")}
        </Button>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col gap-4">
          <Field
            label={t("pause-start")}
            value={startedAtValue.toLocaleDateString("fi-FI")}
          />
          <Field
            label={t("responsible-for-delay")}
            value={tResponsible(responsibleValue, responsibleValue)}
          />
        </div>
        <div className="flex flex-col gap-4">
          <Field label={t("reason-for-delay")} value={reasonFormatted} />
          {additionalInformationValue && (
            <Field
              label={t("additional-information")}
              value={additionalInformationValue}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function OngoingPauseEdit() {
  const { t } = useTranslation("", { keyPrefix: "common.modal.end-pause" });
  const { t: tResponsible } = useTranslation("", {
    keyPrefix: "common.pause-responsible",
  });
  const { t: tReason } = useTranslation("", {
    keyPrefix: "common.pause-reason",
  });

  const [, setIsEditing] = useAtom(isEditingAtom);

  const form = useFormContext<FinishedPauseUpsertValues>();

  const [resetState, setResetState] =
    useState<FinishedPauseUpsertValues | null>(null);

  useEffect(() => {
    if (resetState) return;
    setResetState(form.getValues());
  }, [form, resetState]);

  const handleReset = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      if (!resetState) return;
      form.reset(resetState);
      setIsEditing(false);
    },
    [form, resetState, setIsEditing],
  );

  const handleSave = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      const result = await form.trigger([
        "startedAt",
        "reasonType",
        "otherReason",
        "responsible",
        "additionalInformation",
      ]);
      if (!result) return;
      setIsEditing(false);
    },
    [form, setIsEditing],
  );

  const reasonOptions: SelectOption[] = Object.values(PauseReasonType).map(
    (reason) => ({
      value: reason,
      label: tReason(reason),
    }),
  );
  const responsibleOptions: SelectOption[] = Object.values(
    PauseResponsible,
  ).map((responsible) => ({
    value: responsible,
    label: tResponsible(responsible),
  }));

  const reasonValue = form.watch("reasonType");
  const solvedValue = form.watch("solvedAt");

  const startedAtDisabled: Matcher = {
    after: solvedValue,
  };

  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <h3 className="text-lg font-semibold text-[#1E1B26]">{t("details")}</h3>
        <div className="flex gap-4">
          <Button variant="secondary" type="button" onClick={handleReset}>
            {t("discard-changes")}
          </Button>
          <Button type="button" onClick={handleSave}>
            {t("save-changes")}
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <FormDateField
          name="startedAt"
          label={t("pause-end")}
          placeholder={t("select-end-date")}
          disabledDates={startedAtDisabled}
        />
        <div className="flex flex-col gap-4">
          <FormSelectField
            name="reasonType"
            label={t("reason-for-delay")}
            placeholder={t("select-reason")}
            options={reasonOptions}
            onChange={(value) => {
              form.resetField("solutionType", { defaultValue: undefined });
              if (value === PauseReasonType.OTHER) {
                form.setValue("reasonType", value);
                form.setValue("otherReason", "");
              } else {
                form.setValue("reasonType", value as PauseReasonType);
                form.setValue("otherReason", null);
              }
            }}
          />
          {reasonValue === PauseReasonType.OTHER && (
            <FormInputField
              name="otherReason"
              placeholder={t("reason-for-delay")}
              max={150}
            />
          )}
        </div>
        <FormSelectField
          name="responsible"
          label={t("responsible-for-delay")}
          placeholder={t("select-responsible")}
          options={responsibleOptions}
        />
        <FormTextAreaField
          name="additionalInformation"
          label={t("additional-information")}
          placeholder={t("add-comment")}
          optional
        />
      </div>
    </div>
  );
}

function PauseEndFields() {
  const { t } = useTranslation("", { keyPrefix: "common.modal.end-pause" });
  const { t: tSolution } = useTranslation("", {
    keyPrefix: "common.pause-solution",
  });

  const isEditing = useAtomValue(isEditingAtom);

  const form = useFormContext<FinishedPauseUpsertValues>();

  const reasonValue = form.watch("reasonType");
  const solutionValue = form.watch("solutionType");

  const startedAtValue = form.watch("startedAt");

  const solvedAtDisabled: Matcher = {
    before: startedAtValue,
  };

  const solutionOptions = useMemo(() => {
    if (!reasonValue) return [];
    return pauseSolutionFilteredOptions[reasonValue].map((solution) => ({
      value: solution,
      label: tSolution(solution),
    }));
  }, [reasonValue, tSolution]);

  return (
    <div className={cn("flex flex-col gap-3", isEditing && "opacity-20")}>
      <h3>{t("enter-solution")}</h3>
      <FormDateField
        name="solvedAt"
        label={t("pause-end")}
        placeholder={t("select-end-date")}
        disabledDates={solvedAtDisabled}
        disabled={isEditing}
      />
      <div className="flex flex-col gap-4">
        <FormSelectField
          key={reasonValue}
          name="solution"
          label={t("reason-for-solution")}
          placeholder={t("select-solution")}
          options={solutionOptions}
          disabled={isEditing || !reasonValue}
          message={reasonValue ? undefined : t("select-reason-first")}
          onChange={(value) => {
            if (value === PauseSolutionType.OTHER) {
              form.setValue("solutionType", value);
              form.setValue("otherSolution", "");
            } else {
              form.setValue("solutionType", value as PauseSolutionType);
              form.setValue("otherSolution", null);
            }
          }}
        />
        {solutionValue === PauseSolutionType.OTHER && (
          <FormInputField
            name="otherSolution"
            placeholder={t("reason-for-solution")}
            max={150}
            disabled={isEditing}
          />
        )}
      </div>
    </div>
  );
}

function Field({ label, value }: { label: string; value: string | number }) {
  return (
    <div className="flex flex-col gap-2">
      <Label className="font-bold text-[#7C8B9D]">{label}</Label>
      <span className="truncate text-[#7C8B9D]">{value}</span>
    </div>
  );
}
