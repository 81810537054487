import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { Asset, CreateAsset } from "@/api/generated/data-contracts";
import { apiClient } from "@/api";
import { HttpResponse } from "@/api/generated/http-client";
import { toast } from "sonner";

type MutationData = Asset;
type MutationError = HttpResponse<Asset, Error>;

interface MutationVariables {
  trackingNumber: string;
  device: CreateAsset;
}

export function useMutationUpdateDevice(
  options?: Omit<
    UseMutationOptions<MutationData, MutationError, MutationVariables>,
    "mutationFn"
  >,
) {
  const queryClient = useQueryClient();
  return useMutation<MutationData, MutationError, MutationVariables>({
    ...options,
    mutationFn: async ({ trackingNumber, device }) => {
      const response = await apiClient.assetsByTrackingNumberUpdate(
        trackingNumber,
        device,
      );
      queryClient.invalidateQueries({ queryKey: ["devices"] });
      queryClient.invalidateQueries({ queryKey: ["device-stage-changes"] });
      return response.data;
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to update device");
    },
  });
}
