import { apiClient } from "@/api";
import { CreateAsset } from "@/api/generated/data-contracts";

export async function checkInExistingDevice(
  assetId: number,
  readSerialNumber: string,
) {
  const response = await apiClient.assetsCheckInCreate(assetId, {
    readSerialNumber,
  });

  if (!response.ok) {
    throw new Error("Failed to check in device");
  }

  return response.data;
}

export async function checkInNewDevice(device: CreateAsset) {
  const response = await apiClient.assetsCheckInCreate2(device);

  if (!response.ok) {
    throw new Error("Failed to check in device");
  }

  return response.data;
}
