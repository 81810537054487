import { SimpleTooltip } from "@/components/SimpleTooltip";
import { Button } from "@/components/ui/button";
import { DialogContent, DialogHeader } from "@/components/ui/dialog";

import { Label } from "@/components/ui/label";
import { useQueryPauseLog } from "@/lib/hooks/query/pause/useQueryPauseLog";
import { useModal } from "@/lib/modals/useModal";
import {
  ParsedPause,
  pauseLogOperationToString,
  PauseReasonType,
  PauseSolutionType,
} from "@/lib/types/pause";
import { Loader2Icon } from "lucide-react";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { dateToLocaleString } from "@/lib/dateUtils.ts";

interface PreviewPauseModalProps {
  pause: ParsedPause;
}

export function PreviewPauseModal({ pause }: PreviewPauseModalProps) {
  const { t } = useTranslation("", { keyPrefix: "common.modal.preview-pause" });
  const { t: tResponsible } = useTranslation("", {
    keyPrefix: "common.pause-responsible",
  });
  const { t: tReason } = useTranslation("", {
    keyPrefix: "common.pause-reason",
  });
  const { t: tSolution } = useTranslation("", {
    keyPrefix: "common.pause-solution",
  });

  const { hideModal } = useModal();

  const pauseLogQuery = useQueryPauseLog(pause.id);

  const handleCancel = () => {
    hideModal();
  };

  const solvedByEmail = useMemo(
    () =>
      pauseLogQuery.data
        ?.sort((a, b) => b.editedAt.localeCompare(a.editedAt))
        .find((log) => log.operation === 3)?.editedByEmail,
    [pauseLogQuery.data],
  );

  const startDateAndCreator = useMemo(() => {
    const startedAt = pause.startedAt.toLocaleDateString("fi-en");
    return `${startedAt} (${pause.createdByEmail})`;
  }, [pause]);

  const solvedDateAndCreator = useMemo(() => {
    if (pause.status !== "finished") return undefined;
    const solvedAt = pause.solvedAt.toLocaleDateString("fi-en");
    if (!solvedByEmail) {
      return solvedAt;
    }
    return `${solvedAt} (${solvedByEmail})`;
  }, [pause, solvedByEmail]);

  const reasonFormatted = useMemo(() => {
    const reasonTranslated = tReason(pause.reasonType);
    if (pause.reasonType === PauseReasonType.OTHER) {
      return `${reasonTranslated} (${pause.otherReason})`;
    }
    return reasonTranslated;
  }, [pause, tReason]);

  const solutionFormatted = useMemo(() => {
    if (pause.status !== "finished") return "";
    const solutionTranslated = tSolution(pause.solutionType);
    if (pause.solutionType === PauseSolutionType.OTHER) {
      return `${solutionTranslated} (${pause.otherSolution})`;
    }
    return solutionTranslated;
  }, [pause, tSolution]);

  return (
    <DialogContent className="flex flex-col gap-16 sm:max-w-4xl ">
      <DialogHeader className="text-2xl font-semibold text-[#1B261B]">
        {t("process-timer-pause")}
      </DialogHeader>

      <div className="flex flex-col gap-6">
        <span className="text-lg font-semibold text-[#1E1B26]">
          {t("details")}
        </span>
        <div className="grid grid-cols-2">
          <div className="flex flex-col gap-4">
            <Field label={t("pause-start")} value={startDateAndCreator} />
            <Field
              label={t("responsible-for-delay")}
              value={tResponsible(pause.responsible, pause.responsible)}
            />
          </div>
          <div className="flex flex-col gap-4">
            <Field label={t("reason-for-delay")} value={reasonFormatted} />
            <Field
              label={t("additional-information")}
              value={pause.additionalInformation ?? "-"}
            />
          </div>
        </div>
      </div>

      {pause.status === "finished" && (
        <div className="grid grid-cols-2">
          <div className="flex flex-col gap-4">
            <Field label={t("pause-end")} value={solvedDateAndCreator ?? "-"} />
          </div>
          <div className="flex flex-col gap-4">
            <Field label={t("solution")} value={solutionFormatted} />
          </div>
        </div>
      )}

      <PauseLogSection pause={pause} />

      <div className="flex flex-col gap-4">
        <Button variant="secondary" onClick={handleCancel}>
          {t("close")}
        </Button>
      </div>
    </DialogContent>
  );
}

function PauseLogSection({ pause }: { pause: ParsedPause }) {
  const pauseLogQuery = useQueryPauseLog(pause.id);

  const sortedPauses = useMemo(
    () =>
      pauseLogQuery.data?.sort((a, b) =>
        a.editedAt.localeCompare(b.editedAt),
      ) ?? [],
    [pauseLogQuery.data],
  );

  return (
    <div className="flex flex-col gap-2 text-[#7C8B9D]">
      <span className="font-bold">Log</span>
      {pauseLogQuery.status === "pending" && (
        <Loader2Icon className="size-6 animate-spin" />
      )}
      {pauseLogQuery.status === "error" && <span>Error loading log</span>}
      {pauseLogQuery.status === "success" &&
        sortedPauses.map((log) => (
          <span key={log.editedAt.toString()}>
            {log.editedByEmail} {pauseLogOperationToString(log.operation)} pause{" "}
            {dateToLocaleString(log.editedAt)}
          </span>
        ))}
    </div>
  );
}

function Field({ label, value }: { label: string; value: string | number }) {
  const ref = useRef<HTMLSpanElement>(null);

  const truncated = ref.current
    ? ref.current?.offsetWidth < ref.current?.scrollWidth
    : false;

  return (
    <div className="flex flex-col gap-2">
      <Label className="font-bold text-[#7C8B9D]">{label}</Label>
      <SimpleTooltip label={truncated ? value : undefined}>
        <span ref={ref} className="truncate text-[#7C8B9D]">
          {value}
        </span>
      </SimpleTooltip>
    </div>
  );
}
