import { FormField, FormItem, FormLabel } from "@/components/ui/form";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useFormContext } from "react-hook-form";

export type RadioOptions = {
  value: string;
  label: string;
  disabled?: boolean;
};

export interface FormRadioGroupFieldProps {
  name: string;
  label?: string;
  options: RadioOptions[];
}

export function FormRadioGroupField({
  name,
  options,
  label,
}: FormRadioGroupFieldProps) {
  const form = useFormContext();
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {label && (
            <FormLabel
              htmlFor="pause-status-radio"
              className="font-bold text-[#344054]"
            >
              {label}
            </FormLabel>
          )}
          <RadioGroup
            id="pause-status-radio"
            onValueChange={field.onChange}
            value={field.value}
          >
            {options.map((option) => (
              <FormItem
                key={option.value}
                className="flex items-center space-x-2"
              >
                <RadioGroupItem
                  value={option.value}
                  id={option.value}
                  disabled={option.disabled}
                  className="peer"
                />
                <Label htmlFor={option.value}>{option.label}</Label>
              </FormItem>
            ))}
          </RadioGroup>
        </FormItem>
      )}
    />
  );
}
