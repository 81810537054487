import { pauseReasonSchema, pauseSolutionSchema } from "@/lib/types/pause";
import { z } from "zod";

export const newPauseBaseSchema = z.object({
  startedAt: z.date(),
  responsible: z.string().min(1).max(32),
  additionalInformation: z.string().max(150).optional(),
});

export const ongoingPauseUpsertSchema = newPauseBaseSchema
  .and(
    z.object({
      status: z.literal("ongoing"),
    }),
  )
  .and(pauseReasonSchema);

export const finishedPauseUpsertSchema = newPauseBaseSchema
  .and(
    z.object({
      status: z.literal("finished"),
      solvedAt: z.date(),
    }),
  )
  .and(pauseReasonSchema)
  .and(pauseSolutionSchema);

export const pauseUpsertSchema = z.union([
  ongoingPauseUpsertSchema,
  finishedPauseUpsertSchema,
]);

export type FinishedPauseUpsertValues = z.infer<
  typeof finishedPauseUpsertSchema
>;
export type PauseUpsertValues = z.infer<typeof pauseUpsertSchema>;
