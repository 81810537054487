import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { useRef, useEffect } from "react";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function parseSvgFromFile(file: File, onload: (value: string) => void) {
  let value: string;
  const reader = new FileReader();
  reader.onload = () => {
    if (typeof reader.result !== "string")
      throw new Error("Unexpected file type");
    value = reader.result;
    // Filters out xml prolog (otherwise SVGInline does not work) and html comments
    value = value.replace(/((\?xml)+[^>]+>)|((<!--)+[^>]+-->)/g, "");
    // Also remove any line breaks
    value = value.replace(/\r?\n|\r/g, "");
  };
  reader.onloadend = () => {
    if (reader.readyState === 2) {
      onload(value);
    }
  };
  reader.onabort = () => console.error("file reading was aborted");
  reader.onerror = () => console.error("file reading has failed");

  reader.readAsText(file);
}

export function parseBinaryImageFromFile(
  file: File,
  onload: (value: string) => void,
) {
  let value: string;
  const reader = new FileReader();
  reader.onload = () => {
    const img = new Image();
    img.src = reader.result?.toString() ?? "";
    (img.onload = () => {
      const elem = document.createElement("canvas");
      const height = 60;
      const scaleFactor = height / img.height;
      elem.height = height;
      elem.width = img.width * scaleFactor;
      const ctx = elem.getContext("2d");
      if (!ctx) throw new Error("Could not get 2d context");
      // img.width and img.height will contain the original dimensions
      ctx.drawImage(img, 0, 0, img.width * scaleFactor, height); // height * scaleFactor
      value = ctx.canvas.toDataURL(file.type, 1);

      onload(value);
    }),
      (reader.onerror = (error) => console.error(error));
  };
  reader.onloadend = () => {
    if (reader.readyState === 2) {
      onload(value);
    }
  };
  reader.onabort = () => console.error("file reading was aborted");
  reader.onerror = () => console.error("file reading has failed");

  reader.readAsDataURL(file);
}

type Timer = ReturnType<typeof setTimeout>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SomeFunction = (...args: any[]) => void;

/**
 *
 * @param func The original, non debounced function (You can pass any number of args to it)
 * @param delay The delay (in ms) for the function to return
 * @returns The debounced function, which will run only if the debounced function has not been called in the last (delay) ms
 */
export function useDebouncedFn<Func extends SomeFunction>(
  func: Func,
  delay = 1000,
) {
  const timer = useRef<Timer>();

  useEffect(() => {
    return () => {
      if (!timer.current) return;
      clearTimeout(timer.current);
    };
  }, []);

  const debouncedFunction = ((...args) => {
    const newTimer = setTimeout(() => {
      func(...args);
    }, delay);
    clearTimeout(timer.current);
    timer.current = newTimer;
  }) as Func;

  return debouncedFunction;
}
