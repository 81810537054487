import { atom } from "jotai";
import { atomWithReset, atomWithStorage } from "jotai/utils";

export const isInboundHelpOpenAtom = atomWithStorage(
  "showInboundHelp",
  true,
  undefined,
  { getOnInit: true },
);

export const isAutoPrintAtom = atom<boolean>(false);

export const targetOrderIdAtom = atomWithReset<number | undefined>(undefined);

export const scannedDevicesAtom = atomWithReset<ScannedDevice[]>([]);

export interface ScannedDevice {
  id?: number;
  eolTrackingNumber?: string;
  orderId?: number;
  classificationCategoryId?: number;
  manufacturer?: string;
  productName?: string; // productName
  serialNumber?: string;
  readSerialNumber: string;
  assetNumber?: string; // Device reference number
  extraItems?: string; // Additional information

  // State
  checkInTime?: Date;
  state: "success" | "error" | "loading" | "missing";
  isPrinted: boolean;
  isExtra: boolean;
}
