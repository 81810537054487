import { Pause } from "@/api/generated/data-contracts";
import { differenceInBusinessDays } from "date-fns";
import { z } from "zod";

export enum PauseReasonType {
  DEVICE_LOCKED = "device-locked",
  INSUFFICIENT_INFORMATION = "insufficient-information",
  OTHER = "other",
}

export enum PauseSolutionType {
  PASSWORD_WAS_PROVIDED = "password-was-provided",
  DEVICE_WAS_UNLOCKED_REMOTELY = "device-was-unlocked-remotely",
  DEVICE_SENT_TO_CUSTOMER_FOR_UNLOCKING = "device-sent-to-customer-for-unlocking",
  DEVICE_APPROVED_FOR_DESTROYING = "device-approved-for-destroying",
  INFORMATION_PROVIDED = "information-provided",
  OTHER = "other",
}

export const pauseSolutionFilteredOptions: Record<
  PauseReasonType,
  PauseSolutionType[]
> = {
  [PauseReasonType.DEVICE_LOCKED]: [
    PauseSolutionType.PASSWORD_WAS_PROVIDED,
    PauseSolutionType.DEVICE_WAS_UNLOCKED_REMOTELY,
    PauseSolutionType.DEVICE_SENT_TO_CUSTOMER_FOR_UNLOCKING,
    PauseSolutionType.DEVICE_APPROVED_FOR_DESTROYING,
    PauseSolutionType.OTHER,
  ],
  [PauseReasonType.INSUFFICIENT_INFORMATION]: [
    PauseSolutionType.INFORMATION_PROVIDED,
    PauseSolutionType.DEVICE_APPROVED_FOR_DESTROYING,
    PauseSolutionType.OTHER,
  ],
  [PauseReasonType.OTHER]: [
    PauseSolutionType.PASSWORD_WAS_PROVIDED,
    PauseSolutionType.DEVICE_WAS_UNLOCKED_REMOTELY,
    PauseSolutionType.DEVICE_SENT_TO_CUSTOMER_FOR_UNLOCKING,
    PauseSolutionType.DEVICE_APPROVED_FOR_DESTROYING,
    PauseSolutionType.INFORMATION_PROVIDED,
    PauseSolutionType.OTHER,
  ],
};

export enum PauseResponsible {
  END_CUSTOMER = "end-customer",
  PARTNER = "partner",
  ALSO_PARTNER = "also-partner",
  ALSO = "also",
}

const reasonBaseSchema = z.object({
  reasonType: z
    .nativeEnum(PauseReasonType)
    .refine(
      (status): status is Exclude<PauseReasonType, PauseReasonType.OTHER> =>
        status !== PauseReasonType.OTHER,
      { message: "Invalid reason type" },
    ),
  otherReason: z.union([z.null(), z.undefined()]),
});

const otherReasonSchema = z.object({
  reasonType: z.literal(PauseReasonType.OTHER),
  otherReason: z.string().min(1).max(150),
});

export const pauseReasonSchema = z.union([otherReasonSchema, reasonBaseSchema]);

const solutionBaseSchema = z.object({
  solutionType: z
    .nativeEnum(PauseSolutionType)
    .refine(
      (status): status is Exclude<PauseSolutionType, PauseSolutionType.OTHER> =>
        status !== PauseSolutionType.OTHER,
    ),
  otherSolution: z.union([z.null(), z.undefined()]),
});

const otherSolutionSchema = z.object({
  solutionType: z.literal(PauseSolutionType.OTHER),
  otherSolution: z.string().min(1).max(150),
});

export const pauseSolutionSchema = solutionBaseSchema.or(otherSolutionSchema);

const basePauseSchema = z
  .object({
    id: z.string().uuid(),
    orderId: z.number().int(),
    startedAt: z
      .string()
      .date()
      .transform((val) => new Date(val)),
    responsible: z.string().min(1).max(32),
    additionalInformation: z.string().max(150).optional(),
    removed: z.boolean().default(false),
    createdById: z.number().int(),
    createdByEmail: z.string().email(),
  })
  .and(pauseReasonSchema);

const onGoingPauseSchema = basePauseSchema
  .and(
    z.object({
      status: z.literal("ongoing").default("ongoing"),
      solution: z.undefined(),
    }),
  )
  .and(basePauseSchema)
  .transform((data) => {
    // Omit solution from the data
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { solution, ...pause } = data;
    return {
      ...pause,
      duration: differenceInBusinessDays(new Date(), pause.startedAt),
    };
  });

const finishedPauseSchema = basePauseSchema
  .and(
    z.object({
      status: z.literal("finished").default("finished"),
      solution: z
        .object({
          solvedAt: z
            .string()
            .date()
            .transform((val) => new Date(val)),
        })
        .and(pauseSolutionSchema),
    }),
  )
  .transform((data) => {
    const { solution, ...pause } = data;
    return {
      ...pause,
      duration: differenceInBusinessDays(solution.solvedAt, pause.startedAt),
      solutionType: solution.solutionType,
      otherSolution: solution.otherSolution,
      solvedAt: solution.solvedAt,
    };
  });

const pauseSchema = z.union([finishedPauseSchema, onGoingPauseSchema]);

export type OngoingPause = z.infer<typeof onGoingPauseSchema>;
export type FinishedPause = z.infer<typeof finishedPauseSchema>;
export type ParsedPause = z.infer<typeof pauseSchema>;

export function parsePause(data: Pause): ParsedPause {
  return pauseSchema.parse(data);
}

export interface GroupedPauses {
  ongoing: OngoingPause[];
  finished: FinishedPause[];
  removed: ParsedPause[];
  invalid: unknown[];
}

export function groupParsePauses(data: unknown[]): GroupedPauses {
  return data.reduce<GroupedPauses>(
    (acc, pause) => {
      try {
        const parsed = pauseSchema.parse(pause);
        if (parsed.removed) {
          acc.removed.push(parsed);
        } else if (parsed.status === "ongoing") {
          acc.ongoing.push(parsed);
        } else if (parsed.status === "finished") {
          acc.finished.push(parsed);
        }
      } catch (error) {
        console.error(error);
        acc.invalid.push(pause);
      }
      return acc;
    },
    {
      ongoing: [],
      finished: [],
      removed: [],
      invalid: [],
    },
  );
}

export enum PauseLogOperation {
  Created = 1,
  Edit = 2,
  Solve = 3,
  Delete = 4,
}

export function pauseLogOperationToString(
  operation: PauseLogOperation,
): string {
  switch (operation) {
    case PauseLogOperation.Created:
      return "Created";
    case PauseLogOperation.Edit:
      return "Edit";
    case PauseLogOperation.Solve:
      return "Solve";
    case PauseLogOperation.Delete:
      return "Delete";
    default:
      return "Unknown";
  }
}
