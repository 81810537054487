import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ReactNode, ComponentPropsWithoutRef } from "react";

interface SimpleTooltipProps {
  children: ReactNode;
  label: ReactNode;
  tooltip?: ComponentPropsWithoutRef<typeof Tooltip>;
  trigger?: ComponentPropsWithoutRef<typeof TooltipTrigger>;
  content?: ComponentPropsWithoutRef<typeof TooltipContent>;
}

export function SimpleTooltip({
  children,
  label,
  tooltip,
  trigger,
  content,
}: SimpleTooltipProps) {
  if (!label) {
    return <>{children}</>;
  }
  return (
    <Tooltip {...tooltip}>
      <TooltipTrigger asChild {...trigger}>
        {children}
      </TooltipTrigger>
      <TooltipContent side="bottom" {...content}>
        {label}
      </TooltipContent>
    </Tooltip>
  );
}
