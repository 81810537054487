import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { FormField, FormItem, FormLabel } from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { Matcher } from "react-day-picker";
import { useFormContext } from "react-hook-form";

export interface FormFieldProps {
  name: string;
  label?: string;
  placeholder?: string;
  disabledDates?: Matcher | Matcher[];
  disabled?: boolean;
}

export function FormDateField({
  name,
  label,
  placeholder,
  disabledDates,
  disabled,
}: FormFieldProps) {
  const form = useFormContext();
  const [open, setOpen] = useState(false);
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-col gap-1">
          {label && <FormLabel htmlFor="pause-date">{label}</FormLabel>}
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                disabled={disabled}
                className={cn(
                  "justify-start text-left font-normal",
                  !field.value && "text-muted-foreground",
                )}
              >
                {field.value ? (
                  field.value.toLocaleDateString("fi-FI")
                ) : (
                  <span>{placeholder}</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={field.value}
                onSelect={(e) => {
                  field.onChange(e);
                  setOpen(false);
                }}
                initialFocus
                weekStartsOn={1}
                disabled={disabledDates}
              />
            </PopoverContent>
          </Popover>
        </FormItem>
      )}
    />
  );
}
