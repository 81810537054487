import { JSPrintManager } from "jsprintmanager";
import { atomWithStorage } from "jotai/utils";
import { useQuery } from "@tanstack/react-query";
import { usePrinter } from "./usePrinter";

export const selectedPrinterAtom = atomWithStorage<string | undefined>(
  "selectedPrinter",
  undefined,
  undefined,
  { getOnInit: true },
);

export function useQueryPrinterList() {
  const { isPrinterReady } = usePrinter();
  return useQuery({
    queryKey: ["printerList"],
    queryFn: async () => {
      await JSPrintManager.refreshPrinters();
      const printers = await JSPrintManager.getPrinters();
      return printers as string[];
    },
    enabled: isPrinterReady,
  });
}
