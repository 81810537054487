import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { HttpResponse } from "@/api/generated/http-client";
import { toast } from "sonner";
import { apiClient } from "@/api";
import { GroupedPauses } from "@/lib/types/pause";

type MutationParams = {
  pauseId: string;
};

export function useMutateRemovePause(
  options?: Omit<
    UseMutationOptions<void, HttpResponse<void, Error>, MutationParams>,
    "mutationFn"
  >,
) {
  const queryClient = useQueryClient();
  return useMutation<void, HttpResponse<void, Error>, MutationParams>({
    ...options,
    mutationFn: async ({ pauseId }) => {
      await apiClient.pausesDelete(pauseId);
    },
    onSuccess: (_, { pauseId }) => {
      queryClient.setQueriesData<GroupedPauses>(
        { queryKey: ["pauses"] },
        (oldValue) => {
          if (!oldValue) return oldValue;
          const flattenedPauses = [...oldValue.ongoing, ...oldValue.finished];
          const hasTargetPause = flattenedPauses.some(
            (pause) => pause.id === pauseId,
          );
          if (hasTargetPause) {
            return {
              ...oldValue,
              ongoing: oldValue.ongoing.filter((pause) => pause.id !== pauseId),
              finished: oldValue.finished.filter(
                (pause) => pause.id !== pauseId,
              ),
            };
          }
          return oldValue;
        },
      );
      toast.success("Pause removed");
    },
    onError: () => {
      toast.error("Failed to remove pause");
    },
  });
}
