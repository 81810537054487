import { FormDateField } from "@/components/form/DateField";
import { FormInputField } from "@/components/form/InputField";
import { FormRadioGroupField } from "@/components/form/RadioGroupField";
import { SelectOption, FormSelectField } from "@/components/form/SelectField";
import { FormTextAreaField } from "@/components/form/TextAreaField";
import { Button } from "@/components/ui/button";
import { DialogContent, DialogHeader } from "@/components/ui/dialog";
import { useMutateCreatePause } from "@/lib/hooks/query/pause/useMutateCreatePause";
import {
  pauseUpsertSchema,
  PauseUpsertValues,
} from "@/lib/modals/pauses/pauseFormSchema";
import { useModal } from "@/lib/modals/useModal";
import {
  PauseReasonType,
  PauseResponsible,
  pauseSolutionFilteredOptions,
  PauseSolutionType,
} from "@/lib/types/pause";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { useMemo } from "react";
import { Matcher } from "react-day-picker";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export function AddNewPauseModal({ orderId }: { orderId: number }) {
  const { t } = useTranslation("", { keyPrefix: "common.modal.add-pause" });
  const { t: tResponsible } = useTranslation("", {
    keyPrefix: "common.pause-responsible",
  });
  const { t: tReason } = useTranslation("", {
    keyPrefix: "common.pause-reason",
  });
  const { t: tSolution } = useTranslation("", {
    keyPrefix: "common.pause-solution",
  });
  const { hideModal } = useModal();

  const { mutateAsync, isPending } = useMutateCreatePause();

  const form = useForm<PauseUpsertValues>({
    resolver: zodResolver(pauseUpsertSchema),
    mode: "onBlur",
    defaultValues: {
      status: "ongoing",
      startedAt: new Date(),
      additionalInformation: "",
    },
  });

  const handleSubmit = form.handleSubmit(async (values, event) => {
    event?.preventDefault();
    await mutateAsync({
      orderId: orderId,
      pause: values,
    });
    hideModal();
  });

  const handleCancel = () => {
    hideModal();
  };

  const statusValue = form.watch("status");
  const reasonValue = form.watch("reasonType");
  const solutionValue = form.watch("solutionType");

  const reasonOptions: SelectOption[] = Object.values(PauseReasonType).map(
    (reason) => ({
      value: reason,
      label: tReason(reason),
    }),
  );

  const solutionOptions = useMemo(() => {
    if (!reasonValue) return [];
    return pauseSolutionFilteredOptions[reasonValue].map((solution) => ({
      value: solution,
      label: tSolution(solution),
    }));
  }, [reasonValue, tSolution]);

  const responsibleOptions: SelectOption[] = Object.values(
    PauseResponsible,
  ).map((responsible) => ({
    value: responsible,
    label: tResponsible(responsible),
  }));

  const startedAtValue = form.watch("startedAt");
  const solvedAtValue = form.watch("solvedAt");

  const startedAtDisabled: Matcher = {
    after: solvedAtValue,
  };
  const solvedAtDisabled: Matcher = {
    before: startedAtValue,
  };

  return (
    <DialogContent className="top-5 flex max-h-[97dvh] translate-y-0 flex-col gap-6 sm:max-w-5xl ">
      <DialogHeader className="text-2xl font-semibold text-[#1B261B]">
        {t("add-process-timer-pause")}
      </DialogHeader>
      <FormProvider {...form}>
        <form
          id="new-pause-form"
          onSubmit={(e) => e.preventDefault()}
          className="h-full overflow-y-auto"
        >
          <div className="flex h-full flex-col gap-6 overflow-y-auto">
            <FormRadioGroupField
              name="status"
              label={t("is-the-delay-ongoing-or-finished")}
              options={[
                { value: "ongoing", label: t("ongoing") },
                { value: "finished", label: t("finished") },
              ]}
            />
            <div className="flex flex-col gap-6">
              <FormDateField
                name="startedAt"
                label={t("pause-start")}
                placeholder={t("select-start-date")}
                disabledDates={startedAtDisabled}
              />
              <div className="flex flex-col gap-4">
                <FormSelectField
                  name="reasonType"
                  label={t("reason-for-delay")}
                  placeholder={t("select-reason")}
                  options={reasonOptions}
                  onChange={(value) => {
                    form.resetField("solutionType", {
                      defaultValue: undefined,
                    });
                    if (value === PauseReasonType.OTHER) {
                      form.setValue("reasonType", value);
                      form.setValue("otherReason", "");
                    } else {
                      form.setValue("reasonType", value as PauseReasonType);
                      form.setValue("otherReason", null);
                    }
                  }}
                />
                {reasonValue === PauseReasonType.OTHER && (
                  <FormInputField
                    name="otherReason"
                    placeholder={t("reason-for-delay")}
                    max={150}
                  />
                )}
              </div>
              <FormSelectField
                name="responsible"
                label={t("responsible-for-delay")}
                placeholder={t("select-responsible")}
                options={responsibleOptions}
              />
              <FormTextAreaField
                name="additionalInformation"
                label={t("additional-information")}
                placeholder={t("add-comment")}
                optional
              />
              {statusValue === "finished" && (
                <>
                  <FormDateField
                    name="solvedAt"
                    label={t("pause-end")}
                    placeholder={t("select-end-date")}
                    disabledDates={solvedAtDisabled}
                  />
                  <div className="flex flex-col gap-4">
                    <FormSelectField
                      key={reasonValue}
                      name="solutionType"
                      label={t("reason-for-solution")}
                      placeholder={t("select-solution")}
                      options={solutionOptions}
                      disabled={!reasonValue}
                      message={
                        reasonValue ? undefined : t("select-reason-first")
                      }
                      onChange={(value) => {
                        if (value === PauseSolutionType.OTHER) {
                          form.setValue("solutionType", value);
                          form.setValue("otherSolution", "");
                        } else {
                          form.setValue(
                            "solutionType",
                            value as PauseSolutionType,
                          );
                          form.setValue("otherSolution", null);
                        }
                      }}
                    />
                    {solutionValue === PauseSolutionType.OTHER && (
                      <FormInputField
                        name="otherSolution"
                        placeholder={t("reason-for-solution")}
                        max={150}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
        <div className="grid grid-cols-2 gap-2">
          <Button variant="secondary" type="button" onClick={handleCancel}>
            {t("cancel")}
          </Button>
          <Button
            form="new-pause-form"
            type="button"
            disabled={isPending}
            onClick={handleSubmit}
          >
            {isPending && <Loader2 className="mr-2 animate-spin" />}
            {t("add-pause")}
          </Button>
        </div>
      </FormProvider>
    </DialogContent>
  );
}
