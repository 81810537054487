import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { useFormContext } from "react-hook-form";

export interface FormTextAreaFieldProps {
  name: string;
  label?: string;
  placeholder?: string;
  optional?: boolean;
}

export function FormTextAreaField({
  name,
  label,
  placeholder,
  optional,
}: FormTextAreaFieldProps) {
  const form = useFormContext();
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {label && (
            <FormLabel>
              {label}{" "}
              {optional && <span className="text-[#7C8B9D]">(optional)</span>}
            </FormLabel>
          )}
          <FormControl>
            <Textarea
              placeholder={placeholder}
              className="resize-none"
              {...field}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
